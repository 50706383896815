import axios from '../_client/axios';

export const downloadService = {
    downloadInspections: async (params) => {
        try {
            const response = await axios.post(`/download/inspection`, params, {
                responseType: 'blob'
            });
            return response.data;
        } catch (e) {
            throw e
        }
    },
    downloadFile: async (params) => {
        try {
            const response = await axios.get(params, {
                responseType: 'blob'
            });
            return response.data;
        } catch (e) {
            throw e
        }
    }
};