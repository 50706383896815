import { combineReducers } from 'redux';

import * as app from './app';
import * as common from './common';
import * as auth from './auth';

const rootReducer = combineReducers({
  ...app,
  ...common,
  ...auth
});

export default rootReducer;