import { poleService } from '../_services';
import { message } from 'antd';

export const poleAction = {
    getAllPoles,
    get,
    deleteItem,
    create,
    update,
    getInspectionByPoleId
};

function get(params) {
    return poleService.get(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function getAllPoles(params) {
    return poleService.getAllPoles(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function deleteItem(id) {
    return poleService.delete(id)
        .then(
            resp => {
                message.success('Deleted');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function create(params) {
    return poleService.create(params)
        .then(
            resp => {
                message.success('Created');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(id, params) {
    return poleService.update(id, params)
        .then(
            resp => {
                message.success('Updated');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function getInspectionByPoleId(id, params) {
    return poleService.getInspectionByPoleId(id, params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}