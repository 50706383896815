import React from 'react'
import GoogleMapReact from 'google-map-react';
import { EnvironmentFilled } from '@ant-design/icons';

const Marker = ({ text }) => <div><EnvironmentFilled style={{ color: 'red', fontSize: 20 }} /></div>;

export default function MapView(props) {
    return (
        <div style={{ height: '50vh', width: '50vh' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                defaultCenter={props.location}
                defaultZoom={props.location.radius || 10}
            >
                <Marker
                    lat={props.location.lat}
                    lng={props.location.lng}
                    text="My Marker"
                />
            </GoogleMapReact>
        </div>
    )
}
