import { authServices } from '../_services';
import { message } from 'antd';
import { authConstants } from '../_constants';
import { history } from "../_helpers";

export const authActions = {
    login,
    logout,
    signup,
    validateToken
};

function login(params) {
    return dispatch => {
        return authServices.login(params)
            .then(data => {
                dispatch(success(data));
                if (data.isActive && data.isAdmin) {
                    localStorage.setItem('user', JSON.stringify(data || {}))
                    if (data.sysGenPassword) {
                        history.push('change_password')
                        history.go(0)
                        return null
                    }
                }
                else {
                    message.error('You are not authorized to access this application. Please contact admin');
                }
                return data;
            }, error => {
                dispatch(failure(error.toString()));
                throw error;
            });
    };
    function success(user) { return { type: authConstants.USER_LOGIN, user } }
    function failure(error) { return { type: authConstants.USER_UNAUTHORIZED, error } }
}

function signup(params) {
    return dispatch => {
        return authServices.signup(params)
            .then(
                data => {
                    message.success('Account created successfully!!! Please login');
                    return data;
                },
                error => {
                    console.log(error)
                    throw error;
                }
            );
    };
}

function validateToken() {
    return dispatch => {
        return authServices.validateToken()
            .then(
                data => {
                    if (data.isActive && data.isAdmin)
                        if (!data.sysGenPassword) {
                            dispatch(success(data));
                        } else {
                            history.push('change_password')
                            history.go(0)
                        }
                    else
                        dispatch(failure());
                    return data;
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function success(user) { return { type: authConstants.USER_LOGIN, user } }
    function failure(error) { return { type: authConstants.USER_UNAUTHORIZED, error } }
}

function logout() {
    authServices.logout();
    return { type: 'USER_LOGOUT' };
}