import { inspectionService } from '../_services';
import { message } from 'antd';

export const inspectionAction = {
    getAllInspections,
    deleteItem,
    create,
    update,
    get
};

function get(params) {
    return inspectionService.get(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function getAllInspections(params) {
    return inspectionService.getAllInspections(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function deleteItem(id) {
    return inspectionService.delete(id)
        .then(
            resp => {
                message.success('Deleted');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function create(params) {
    return inspectionService.create(params)
        .then(
            resp => {
                message.success('Created');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(id, params) {
    return inspectionService.update(id, params)
        .then(
            resp => {
                message.success('Updated');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
