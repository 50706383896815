import { optionService } from '../_services';
import { message } from 'antd';

export const optionAction = {
    getAllOptions,
    changeStatus,
    create,
    update,
    checkCodeAvailability
};

function getAllOptions(type, params) {
    return optionService.getAllOptions(type, params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function changeStatus(type, id, params) {
    return optionService.changeStatus(type, id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function create(type, params) {
    return optionService.create(type, params)
        .then(
            resp => {
                message.success('Created');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(type, id, params) {
    return optionService.update(type, id, params)
        .then(
            resp => {
                message.success('Updated');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function checkCodeAvailability(type, id) {
    return optionService.checkCodeAvailability(type, id)
        .then(
            resp => {
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
