import { commonConstants } from '../../_constants';
let INITIAL_STATE = {};
export function imageUploader(state = INITIAL_STATE, action) {
    switch (action.type) {
        case commonConstants.IMAGE_UPLOAD_REQUEST:
            return { imageUploading: action.data.key };
        case commonConstants.IMAGE_UPLOAD_SUCCESS:
            return INITIAL_STATE;
        case commonConstants.IMAGE_UPLOAD_FAILURE:
            return INITIAL_STATE;
        default:
            return state
    }
}