import React, { Component } from 'react'
import { Col, Row, Card, Popover, Table, Space, Checkbox, Tooltip, Popconfirm, PageHeader, Button, Descriptions, Input } from 'antd';
import moment from "moment";
import { poleAction, optionAction, powerlineAction } from '../../redux/_actions';
import UpdatePole from '../../components/Forms/Poles/Update';
import { EnvironmentFilled, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Breadcrumb } from 'antd';
import Loader from '../../components/Loader';
import MapView from '../../components/MapView';

const { Meta } = Card;

export default class PoleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            loading: {},
            pole: {},
            powerlines: [],
            inspections: [],
            classCodes: [],
            manufacturerCodes: [],
            sideCodes: [],
            directionCodes: [],
            speciesCodes: [],
            originalTreatmentCodes: [],
            modal: {
                addForm: false
            }
        }
    }
    componentDidMount = () => {
        this.getPole();
        this.getInspectionByPoleId();
    }
    getPole = async () => {
        try {
            this.toggleLoading('poleLoading');
            const id = this.props.match.params.id;
            const resp = await poleAction.get({ id });
            this.setState({
                loading: { ...this.state.loading, poleLoading: false },
                id, pole: resp || {}
            });
        } catch (error) {
            console.log(error)
            this.toggleLoading('poleLoading');
        }
    }
    toggleLoading = (key) => {
        const { loading } = this.state;
        this.setState({ loading: { ...loading, [key]: !loading[key] } })
    }
    toggleModal = (key) => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, [key]: !modal[key] } })
    }
    updateAndGet = async (params) => {
        try {
            this.toggleLoading('updatePole');
            await poleAction.update(this.state.pole.id, { ...params })
            this.setState({
                modal: { ...this.state.modal, updateForm: false },
                loading: { ...this.state.loading, updatePole: false }
            });
            this.getPole();
        } catch (error) {
            this.toggleLoading('updatePole');
        }
    }
    deleteRecord = async (params) => {
        try {
            this.toggleLoading('deletePole');
            await poleAction.deleteItem(params)
            this.setState({
                loading: { ...this.state.loading, deletePole: false }
            });
            this.props.history.push(`/poles`)
        } catch (error) {
            this.toggleLoading('deletePole');
        }
    }
    getAllPowerlines = async (params) => {
        try {
            this.toggleLoading('powerlinesLoading');
            const resp = await powerlineAction.getAllPowerlines(params);
            this.setState({ loading: { ...this.state.loading, powerlinesLoading: false }, powerlines: resp.powerlines || [] });
        } catch (error) {
            this.toggleLoading('powerlinesLoading');
        }
    }
    getAllClassCodes = async (params) => {
        try {
            this.toggleLoading('classCodesLoading');
            const resp = await optionAction.getAllOptions('class', params);
            this.setState({ loading: { ...this.state.loading, classCodesLoading: false }, classCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('classCodesLoading');
        }
    }
    getAllManufacturerCodes = async (params) => {
        try {
            this.toggleLoading('manufacturerCodesLoading');
            const resp = await optionAction.getAllOptions('manufacturer', params);
            this.setState({ loading: { ...this.state.loading, manufacturerCodesLoading: false }, manufacturerCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('manufacturerCodesLoading');
        }
    }
    getAllSideCodes = async (params) => {
        try {
            this.toggleLoading('sideCodesLoading');
            const resp = await optionAction.getAllOptions('side', params);
            this.setState({ loading: { ...this.state.loading, sideCodesLoading: false }, sideCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('sideCodesLoading');
        }
    }
    getAllDirectionCodes = async (params) => {
        try {
            this.toggleLoading('directionCodesLoading');
            const resp = await optionAction.getAllOptions('direction', params);
            this.setState({ loading: { ...this.state.loading, directionCodesLoading: false }, directionCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('directionCodesLoading');
        }
    }
    getAllSpeciesCodes = async (params) => {
        try {
            this.toggleLoading('speciesCodesLoading');
            const resp = await optionAction.getAllOptions('species', params);
            this.setState({ loading: { ...this.state.loading, speciesCodesLoading: false }, speciesCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('speciesCodesLoading');
        }
    }
    getAllOriginalTreatmentCodes = async (params) => {
        try {
            this.toggleLoading('originalTreatmentCodesLoading');
            const resp = await optionAction.getAllOptions('originalTreatment', params);
            this.setState({ loading: { ...this.state.loading, originalTreatmentCodesLoading: false }, originalTreatmentCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('originalTreatmentCodesLoading');
        }
    }
    getInspectionByPoleId = async (filters) => {
        try {
            this.toggleLoading('inspectionsLoading');
            const id = this.props.match.params.id;
            const resp = await poleAction.getInspectionByPoleId(id, filters);
            this.setState({
                loading: { ...this.state.loading, inspectionsLoading: false },
                id, inspections: resp.inspections || {}
            });
        } catch (error) {
            console.log(error)
            this.toggleLoading('inspectionsLoading');
        }
    }
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        this.getInspectionByPoleId()
    };
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.inspectionSearchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.inspectionSearchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            inspectionSearchText: selectedKeys[0],
            inspectionSearchedColumn: dataIndex,
        });
        this.getInspectionByPoleId({
            searchKey: dataIndex,
            searchValue: selectedKeys[0],
            page: 1
        });
    };
    render() {
        const { pole } = this.state;
        const inspectionColumns = [
            {
                title: 'Pole #',
                key: 'pole',
                render: record => <>{record.poleId}</>,
            },
            {
                title: 'Direction',
                align: 'center',
                key: 'directionCode',
                render: record => <>{record?.pole?.directionCode}</>,
            },
            {
                title: 'Year',
                align: 'center',
                key: 'year',
                render: record => <>{record?.pole?.year}</>,
            },
            {
                title: 'Species',
                align: 'center',
                key: 'speciesCode',
                render: record => <>{record?.pole?.speciesCode}</>,
            },
            {
                title: 'Original Treatment',
                align: 'center',
                key: 'originalTreatmentCode',
                render: record => <>{record?.pole?.originalTreatmentCode}</>,
            },
            {
                title: 'Manufacturer',
                align: 'center',
                key: 'manufacturerCode',
                render: record => <>{record?.pole?.manufacturerCode}</>,
            },
            {
                title: 'Location',
                key: 'location',
                align: 'center',
                render: record => <Popover content={<MapView location={{ lat: record.lat, lng: record.lng }} />} title="Location" trigger="click">
                    <EnvironmentFilled />
                </Popover>
            },
            {
                title: 'Created By',
                dataIndex: 'createdUser',
                key: 'createdUser',
                render: record => <span>{record?.name}</span>,
            },
            {
                title: 'Created At',
                dataIndex: 'createdOn',
                key: 'createdOn',
                render: record => <>{moment(record).format('YYYY-MM-DD HH:mm')}</>,
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <Tooltip title="View inspection details">
                            <EyeOutlined onClick={() => this.props.history.push(`/inspections/${record.id}`)} />
                        </Tooltip>
                    </Space>
                ),
            }
        ];
        return (
            <div className='details-container'>
                <Loader loading={this.state.loading.poleLoading || this.state.loading.updatePole || this.state.loading.deletePole} text={"Updating status"} />
                {this.state.modal.updateForm && <UpdatePole
                    loading={this.state.loading}
                    initialValues={this.state.pole}
                    powerlines={this.state.powerlines}
                    classCodes={this.state.classCodes}
                    manufacturerCodes={this.state.manufacturerCodes}
                    sideCodes={this.state.sideCodes}
                    directionCodes={this.state.directionCodes}
                    speciesCodes={this.state.speciesCodes}
                    originalTreatmentCodes={this.state.originalTreatmentCodes}
                    onSearch={this.onSearchFormFields}
                    onCreate={(e) => {
                        this.updateAndGet(e);
                        this.setState({ pole: {} })
                    }}
                    onCancel={() => {
                        this.toggleModal('updateForm');
                        this.setState({ pole: {} })
                    }}
                />}
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Poles</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.props.match.params.id}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-page-header-ghost-wrapper">
                    <PageHeader
                        ghost={false}
                        title={`Created by ${pole?.createdUser?.name}`}
                        subTitle={`Updated by ${pole?.updatedUser?.name}`}
                        extra={[
                            <Button key="2" onClick={() => {
                                this.getAllPowerlines()
                                this.getAllClassCodes()
                                this.getAllManufacturerCodes()
                                this.getAllSideCodes()
                                this.getAllDirectionCodes()
                                this.getAllSpeciesCodes()
                                this.getAllOriginalTreatmentCodes()
                                this.toggleModal('updateForm')
                            }}>Edit</Button>,
                            <Popconfirm
                                key="3"
                                title="Are you sure delete this pole?"
                                onConfirm={() => this.deleteRecord(this.props.match.params.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button>Delete</Button>,
                            </Popconfirm>
                        ]}
                    >
                        <Descriptions size="small" column={2}>
                            <Descriptions.Item label="Created">{pole?.createdUser?.name}</Descriptions.Item>
                            <Descriptions.Item label="Updated">{pole?.updatedUser?.name}</Descriptions.Item>
                            <Descriptions.Item label="Created At">{moment(pole?.createdOn).format('DD-MM-YYYY')}</Descriptions.Item>
                            <Descriptions.Item label="Updated At">{pole?.updatedOn ? moment(pole?.updatedOn).format('DD-MM-YYYY') : 'No updation'}</Descriptions.Item>
                        </Descriptions>
                    </PageHeader>
                </div>
                <Card
                    style={{ width: '100%', height: '100%', marginTop: 16 }}>
                    <div className="site-card-wrapper">
                        <Row gutter={12}>
                            <Col span={6}>
                                <Card title="Pole" bordered={true}>
                                    {pole?.powerLine?.lineName || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Pole Number" bordered={true}>
                                    {pole?.poleNumber || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Pole Position" bordered={true}>
                                    {pole?.polePosition || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Class" bordered={true}>
                                    {pole?.class?.code || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Card title="Year" bordered={true}>
                                    {pole?.year || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Estimated" bordered={true}>
                                    {pole?.estimated ? 'Yes' : 'No'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Height" bordered={true}>
                                    {pole?.height || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Original Treatment" bordered={true}>
                                    {pole?.originalTreatment?.code || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Card title="Manufacturer" bordered={true}>
                                    {pole?.manufacturer?.code || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Township" bordered={true}>
                                    {pole?.township || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Range" bordered={true}>
                                    {pole?.range || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Section" bordered={true}>
                                    {pole?.section || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="Side" bordered={true}>
                                    {pole?.side?.code || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Direction" bordered={true}>
                                    {pole?.direction?.code || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Species" bordered={true}>
                                    {pole?.species?.code || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card title="Location" bordered={true}>
                                    <Popover content={<MapView location={{ lat: pole.lat, lng: pole.lng }} />} title="Location" trigger="click">
                                        <EnvironmentFilled />
                                    </Popover>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card title="Construction" bordered={true}>
                                    {pole?.construction || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Table rowKey={'id'}
                            columns={inspectionColumns}
                            loading={this.state.loading.inspectionsLoading} bordered dataSource={this.state.inspections || []} />
                    </div>
                </Card>
            </div>
        )
    }
}
