import React, { Component } from 'react';

class Page404 extends Component {
    componentDidMount = () => {
        this.setInterval = setTimeout(() => {
            console.log(this.props)
            this.props.history.push('/')
        }, 5000)
    }
    ccomponentDidMount = () => {
        this.setInterval = null;
    }
    render() {
        return (
            <div className="app flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="clearfix">
                                <h1 className="float-left display-3 mr-2">404</h1>
                                <h4 className="pt-1">Oops! You're lost.</h4>
                                <p className="text-muted">The page you are looking for was not found.</p>
                                <p className="text-muted">Redirecting to homepage in 5sec </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Page404;
