import React from 'react';
import { Button, Modal, Space, Form, Input, Select, Radio, DatePicker } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const { Option } = Select;

const Download = ({ onSubmit, onCancel, loading }) => {
    const [form] = Form.useForm();
    function handleOk() {
        form
            .validateFields()
            .then((values) => {
                onSubmit({ ...values });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    function handleCancel() {
        onCancel()
        form.resetFields();
    }
    return (
        <Modal
            visible={true}
            title="Download Inspections"
            onCancel={handleCancel}
            onOk={handleOk}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading.downloadForm} onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    type: 'csv'
                }}
            >
                <Form.Item name="type" label="Type"
                    rules={[]}>
                    <Radio.Group>
                        <Radio value={'csv'}>CSV</Radio>
                        <Radio value={'pdf'}>Pdf</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="fileName"
                    label="File Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the filename  !',
                        }
                    ]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default Download;