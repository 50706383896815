import axios from '../_client/axios';

export const powerlineService = {
    getAllPowerlines: async (params) => {
        try {
            const response = await axios.get(`/powerline`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    changeStatus: async (id, params) => {
        try {
            const response = await axios.put(`/powerline/${id}/status`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    create: async (params) => {
        try {
            const response = await axios.post(`/powerline`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    update: async (id, params) => {
        try {
            const response = await axios.put(`/powerline/${id}`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    delete: async (id, params) => {
        try {
            const response = await axios.delete(`/powerline/${id}`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    }
};