import React, { Component } from 'react'
import { Table, Tag, Input, Space, Button, Popconfirm } from 'antd';
import { SyncOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { optionAction } from '../../redux/_actions';
import AddNewOption from '../../components/Forms/Options/Add';
import UpdateOption from '../../components/Forms/Options/Update';
import { Breadcrumb } from 'antd';
import constants from "../../constants";
import Loader from '../../components/Loader';

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            route: null,
            loading: {},
            selectedRecord: {},
            options: [],
            totalOptions: 0,
            optionsLoading: false,
            searchText: '',
            searchedColumn: '',
            page: 1,
            modal: {
                addForm: false
            }
        }
    }
    componentDidMount = () => {
        const record = this.getRouteRecord();
        this.setState({ record, route: record.route })
        this.getAllOptions()
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps == undefined) {
            return false
        }
        if (this.state.route && this.state.route != this.props.match.params.type) {
            const record = this.getRouteRecord();
            this.setState({ record, route: record.route })
            this.getAllOptions()
        }

    }
    toggleModal = (key) => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, [key]: !modal[key] } })
    }
    toggleLoading = (key) => {
        const { loading } = this.state;
        this.setState({ loading: { ...loading, [key]: !loading[key] } })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
        this.getAllOptions({
            searchKey: dataIndex,
            searchValue: selectedKeys[0],
            page: 1
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        this.getAllOptions()
    };
    getAllOptions = async (params) => {
        try {
            const record = this.getRouteRecord();;
            this.setState({ optionsLoading: true });
            const resp = await optionAction.getAllOptions(record.type, params);
            this.setState({ optionsLoading: false, options: resp.options || [], totalOptions: resp.total });
        } catch (error) {
            this.setState({ optionsLoading: false });
        }

    }
    confirmStatusChange = async (id, params) => {
        try {
            this.toggleLoading('statusChange');
            await optionAction.changeStatus(this.state.record.type, id, params)
            this.getAllOptions();
            this.toggleLoading('statusChange');
        } catch (error) {
            this.toggleLoading('statusChange');
        }
    }
    onChangePage = (newPage) => {
        const { searchedColumn, searchText, page } = this.state;
        if (page != newPage) { // To prevent unwanted API calls
            const params = { page: newPage };
            if (searchedColumn && searchText) {
                params['searchKey'] = searchedColumn
                params['searchValue'] = searchText
            }
            this.getAllOptions(params);
            this.setState({ page: newPage })
        }
    }
    createAndList = async (params) => {
        try {
            this.toggleLoading('addOption');
            await optionAction.create(this.state.record.type, params)
            this.setState({
                modal: { ...this.state.modal, addForm: false },
                loading: { ...this.state.loading, addOption: false }
            });
            this.getAllOptions();
        } catch (error) {
            this.toggleLoading('addOption');
            this.toggleModal('addForm');
        }
    }
    updateAndList = async (params) => {
        try {
            this.toggleLoading('updateOption');
            await optionAction.update(this.state.record.type, this.state.selectedRecord.code, params)
            this.setState({
                modal: { ...this.state.modal, updateForm: false },
                loading: { ...this.state.loading, updateOption: false }
            });
            this.getAllOptions();
        } catch (error) {
            this.toggleLoading('updateOption');
            this.toggleModal('updateForm');
        }
    }
    getOptionType = () => {
        return Object.keys(constants.OPTIONS).filter((item) => { return constants.OPTIONS[item].route == this.props.match.params.type });
    }
    getRouteRecord = () => {
        const key = this.getOptionType();
        if (!key.length) this.props.history.push('/options/sides')
        else return constants.OPTIONS[key];
    }
    render() {
        const columns = [
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
                ...this.getColumnSearchProps('code'),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('description'),
                ellipsis: true,
            },
            {
                title: 'Status',
                key: 'isActive',
                width: '15%',
                render: record =>
                    <Space size="middle">
                        <Popconfirm
                            title={`Are you sure want to ${record.isActive ? 'deactivate' : 'activate'} this option?`}
                            onConfirm={() => this.confirmStatusChange(record.code, {
                                status: record.isActive ? 'deactivate' : 'activate'
                            })}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tag color={record.isActive ? 'green' : 'red'}>{record.isActive ? 'ACTIVE' : 'DEACTIVATED'}</Tag> <SyncOutlined style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                    </Space>
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <EditOutlined
                            onClick={() => {
                                this.toggleModal('updateForm')
                                this.setState({ selectedRecord: record })
                            }} />
                    </Space>
                ),
            }
        ];
        return (
            <div>
                <Loader loading={this.state.loading.statusChange} text={"Updating status"} />
                {this.state.modal.addForm && <AddNewOption
                    loading={this.state.loading.addOption}
                    type={this.state.record.type}
                    checkCodeAvailability={optionAction.checkCodeAvailability}
                    onCreate={this.createAndList}
                    onCancel={() => this.toggleModal('addForm')}
                />}
                {this.state.modal.updateForm && <UpdateOption
                    loading={this.state.loading.updateOption}
                    initialValues={this.state.selectedRecord}
                    type={this.state.record.type}
                    checkCodeAvailability={optionAction.checkCodeAvailability}
                    onCreate={(e) => {
                        this.updateAndList(e);
                        this.setState({ selectedRecord: {} })
                    }}
                    onCancel={() => {
                        this.toggleModal('updateForm');
                        this.setState({ selectedRecord: {} })
                    }}
                />}
                <Breadcrumb>
                    <Breadcrumb.Item key="1">Home</Breadcrumb.Item>
                    <Breadcrumb.Item key="2">Options</Breadcrumb.Item>
                    <Breadcrumb.Item key="3">{this.state.record.label}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="add-new-btn">
                    <Button
                        type="primary"
                        onClick={() => {
                            this.toggleModal('addForm')
                        }}>
                        New Option
                    </Button>
                </div>
                <Table rowKey={'code'}
                    pagination={{
                        onChange: this.onChangePage,
                        total: this.state.totalOptions
                    }}
                    columns={columns}
                    loading={this.state.optionsLoading} bordered dataSource={this.state.options} />
            </div>
        )
    }
}

export default Options;
