import React, { Component } from 'react'
import { Table, Tag, Input, Space, Button, Popconfirm } from 'antd';
import { DeleteOutlined, SearchOutlined, EditOutlined, FilterFilled } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { powerlineAction, customerAction, countyAction } from '../../redux/_actions';
import AddNewPowerline from '../../components/Forms/Powerlines/Add';
import UpdatePowerline from '../../components/Forms/Powerlines/Update';
import FilterPowerlines from '../../components/Forms/Powerlines/Filter';
import { Breadcrumb } from 'antd';
import Loader from '../../components/Loader';
import _ from 'lodash';

class Powerlines extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {},
            selectedRecord: {},
            powerlines: [],
            totalPowerlines: 0,
            powerlinesLoading: false,
            searchText: '',
            searchedColumn: '',
            customers: [],
            page: 1,
            modal: {
                addForm: false
            },
            filters: {}
        }
    }
    componentDidMount = () => {
        this.getAllPowerlines()
        this.getAllCustomers()
    }
    toggleModal = (key) => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, [key]: !modal[key] } })
    }
    toggleLoading = (key) => {
        const { loading } = this.state;
        this.setState({ loading: { ...loading, [key]: !loading[key] } })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
        this.getAllPowerlines({
            searchKey: dataIndex,
            searchValue: selectedKeys[0],
            page: 1
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        this.getAllPowerlines()
    };
    getAllPowerlines = async (params) => {
        try {
            this.setState({ powerlinesLoading: true });
            const resp = await powerlineAction.getAllPowerlines(params);
            this.setState({ powerlinesLoading: false, powerlines: resp.powerlines || [], totalPowerlines: resp.total });
        } catch (error) {
            this.setState({ powerlinesLoading: false });
        }

    }
    getAllCustomers = async (params) => {
        try {
            this.toggleLoading('customersLoading');
            const resp = await customerAction.getAllCustomers(params);
            this.setState({ loading: { ...this.state.loading, customersLoading: false }, customers: resp.customers || [] });
        } catch (error) {
            this.toggleLoading('customersLoading');
        }
    }
    confirmStatusChange = async (id, params) => {
        try {
            this.toggleLoading('statusChange');
            await powerlineAction.changeStatus(id, params)
            this.getAllPowerlines();
            this.toggleLoading('statusChange');
        } catch (error) {
            this.toggleLoading('statusChange');
        }
    }
    onChangePage = (newPage) => {
        const { searchedColumn, searchText, page } = this.state;
        if (page != newPage) { // To prevent unwanted API calls
            const params = { page: newPage };
            if (searchedColumn && searchText) {
                params['searchKey'] = searchedColumn
                params['searchValue'] = searchText
            }
            this.getAllPowerlines(params);
            this.setState({ page: newPage })
        }
    }
    createAndList = async (params) => {
        try {
            this.toggleLoading('addPowerline');
            await powerlineAction.create(params)
            this.setState({
                modal: { ...this.state.modal, addForm: false },
                loading: { ...this.state.loading, addPowerline: false }
            });
            this.getAllPowerlines();
        } catch (error) {
            this.toggleLoading('addPowerline');
        }
    }
    updateAndList = async (params) => {
        try {
            this.toggleLoading('updatePowerline');
            await powerlineAction.update(this.state.selectedRecord.id, params)
            this.setState({
                modal: { ...this.state.modal, updateForm: false },
                loading: { ...this.state.loading, updatePowerline: false }
            });
            this.getAllPowerlines();
        } catch (error) {
            this.toggleLoading('updatePowerline');
        }
    }
    deleteAndList = async (params) => {
        try {
            this.toggleLoading('deletePowerline');
            await powerlineAction.deleteItem(params)
            this.setState({
                loading: { ...this.state.loading, deletePowerline: false }
            });
            this.getAllPowerlines();
        } catch (error) {
            this.toggleLoading('deletePowerline');
        }
    }
    onClickFilter = async (filters) => {
        try {
            console.log(filters)
            this.toggleLoading('filterForm');
            this.getAllPowerlines(filters)
            this.setState({
                filters,
                modal: { ...this.state.modal, filterForm: false },
                loading: { ...this.state.loading, filterForm: false }
            });
        } catch (error) {
            this.toggleLoading('filterForm');
        }
    }
    onCloseFilter = (key) => {
        const { filters } = this.state;
        if (key === 'from' || key === 'to') {
            delete filters['from'];
            delete filters['to'];
        } else {
            delete filters[key];
        }
        this.setState({ filters })
        this.getAllPowerlines(filters)
    }
    onSearchFormFields = (key, value) => {
        switch (key) {
            case 'customerId':
                this.getAllCustomers({ searchKey: 'customerName', searchValue: value })
                break;
            default:
                break;
        }
    }
    render() {
        const columns = [
            {
                title: 'Line Name',
                dataIndex: 'lineName',
                key: 'lineName',
                ...this.getColumnSearchProps('lineName'),
            },
            {
                title: 'Line',
                dataIndex: 'line',
                key: 'line',
                ...this.getColumnSearchProps('line'),
            },
            {
                title: 'Sub Station',
                dataIndex: 'subStation',
                key: 'subStation',
                ...this.getColumnSearchProps('subStation'),
            },
            {
                title: 'Customer',
                dataIndex: 'customer',
                key: 'customer',
                render: record => <span>{record.customerName}</span>,
            },
            {
                title: 'Created By',
                dataIndex: 'createdUser',
                key: 'createdUser',
                render: record => <span>{record.name}</span>,
            },
            {
                title: 'Updated By',
                dataIndex: 'updatedUser',
                key: 'updatedUser',
                render: record => <span>{record.name}</span>,
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <EditOutlined
                            onClick={() => {
                                this.toggleModal('updateForm')
                                this.setState({ selectedRecord: record })
                            }} />
                        <Popconfirm
                            title={`Are you sure want to delete this power line?`}
                            onConfirm={() => this.deleteAndList(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    </Space>
                ),
            }
        ];
        console.log(this.state)
        return (
            <div>
                <Loader loading={this.state.loading.addPowerline || this.state.loading.updatePowerline || this.state.loading.deletePowerline} text={"Updating status"} />
                {this.state.modal.addForm && <AddNewPowerline
                    loading={this.state.loading}
                    onCreate={this.createAndList}
                    customers={this.state.customers}
                    onSearch={this.onSearchFormFields}
                    onCancel={() => this.toggleModal('addForm')}
                />}
                {this.state.modal.updateForm && <UpdatePowerline
                    loading={this.state.loading}
                    initialValues={this.state.selectedRecord}
                    customers={this.state.customers}
                    onSearch={this.onSearchFormFields}
                    onCreate={(e) => {
                        this.updateAndList(e);
                        this.setState({ selectedRecord: {} })
                    }}
                    onCancel={() => {
                        this.toggleModal('updateForm');
                        this.setState({ selectedRecord: {} })
                    }}
                />}
                {this.state.modal.filterForm &&
                    <FilterPowerlines
                        loading={this.state.loading}
                        customers={this.state.customers}
                        onSearch={this.onSearchFormFields}
                        onSubmit={(e) => {
                            this.onClickFilter(e)
                        }}
                        onCancel={() => {
                            this.setState({ filters: {} })
                            this.toggleModal('filterForm');
                        }}
                    />
                }
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Powerlines</Breadcrumb.Item>
                </Breadcrumb>
                <div className="row">
                    <div className="col-md-9">
                        <div className="filter-container">
                            {Object.keys(this.state.filters).map(item => <Tag closable color="processing" onClose={() => this.onCloseFilter(item)}>
                                {item.toUpperCase(this)} : {this.state.filters[item]}
                            </Tag>)}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="add-new-btn">
                            <Button
                                type="primary"
                                onClick={() => {
                                    this.toggleModal('addForm')
                                }}>
                                New Powerline
                            </Button>
                            <Button
                                type="primary"
                                style={{ marginLeft: '10px' }}
                                icon={<FilterFilled />}
                                onClick={() => {
                                    this.toggleModal('filterForm')
                                }}>
                                Filter
                            </Button>
                        </div>
                    </div>
                </div>
                <Table rowKey={'id'}
                    pagination={{
                        onChange: this.onChangePage,
                        total: this.state.totalPowerlines
                    }}
                    columns={columns}
                    loading={this.state.powerlinesLoading} bordered dataSource={this.state.powerlines} />
            </div>
        )
    }
}

export default Powerlines;
