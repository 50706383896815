import { jobService } from '../_services';
import { message } from 'antd';

export const jobAction = {
    getAllJobs,
    changeStatus,
    create,
    update
};

function getAllJobs(params) {
    return jobService.getAllJobs(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function changeStatus(id, params) {
    return jobService.changeStatus(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function create(params) {
    return jobService.create(params)
        .then(
            resp => {
                message.success('Created');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(id, params) {
    return jobService.update(id, params)
        .then(
            resp => {
                message.success('Updated');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
