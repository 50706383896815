import React from 'react';
import { Router, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Full from './layouts/Full';

import { history } from './redux/_helpers';
import { AuthRoute } from './components/AuthRoute';
import Login from './views/Login';
import Page500 from './views/Errors/Page500';
import Page404 from './views/Errors/Page404';
import Page401 from './views/Errors/Page401';
import Signup from './views/Signup';
import ChangePassword from './views/ChangePassword';
import Users from './views/Users';
import Customers from './views/Customers';
import Options from './views/Options';
import InspectionCompanies from './views/InspectionCompanies';
import Counties from './views/Counties';
import Powerlines from './views/Powerlines';
import Inspections from './views/Inspections';
import Poles from './views/Poles';
import InspectionTypes from './views/InspectionTypes';
import InspectionSubTypes from './views/InspectionSubTypes';
import InspectionDetails from './views/Inspections/InspectionDetails';
import PoleDetails from './views/Poles/PoleDetails';
import Jobs from './views/Jobs';
import JobApplicants from './views/JobApplicants';

class Main extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/signup" component={Signup} /> */}
          <Route exact path="/page404" component={Page404} />
          <Route exact path="/page500" component={Page500} />
          <Route exact path="/page401" component={Page401} />
          <AuthRoute exact path="/change_password" user={user} component={ChangePassword} />
          <Full>
            <Switch>
              <AuthRoute exact path="/users" user={user} component={Users} />
              <AuthRoute exact path="/customers" user={user} component={Customers} />
              <AuthRoute exact path="/jobs" user={user} component={Jobs} />
              <AuthRoute exact path="/jobs/:id/applicants" user={user} component={JobApplicants} />
              <AuthRoute exact path="/inspection_companies" user={user} component={InspectionCompanies} />
              <AuthRoute exact path="/counties" user={user} component={Counties} />
              <AuthRoute exact path="/powerlines" user={user} component={Powerlines} />
              <AuthRoute exact path="/inspections" user={user} component={Inspections} />
              <AuthRoute exact path="/inspections/:id" user={user} component={InspectionDetails} />
              <AuthRoute exact path="/poles" user={user} component={Poles} />
              <AuthRoute exact path="/poles/:id" user={user} component={PoleDetails} />
              <AuthRoute exact path="/options/:type" user={user} component={Options} />
              <AuthRoute exact path="/inspection_type" user={user} component={InspectionTypes} />
              <AuthRoute exact path="/inspection_sub_type" user={user} component={InspectionSubTypes} />
              <Redirect from="*" to="/inspections" />
            </Switch>
          </Full>
        </Switch>
      </BrowserRouter>
    );
  }
}

function mapState(state) {
  const { user } = state.authState;
  return { user };
}

const actionCreators = {
};

const connectedMain = connect(mapState, actionCreators)(Main);
export { connectedMain as Main };