import axios from '../_client/axios';

export const countyService = {
    getAllCounties: async (params) => {
        try {
            const response = await axios.get(`/county`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    }
};