import { customerService } from '../_services';
import { message } from 'antd';

export const customerAction = {
    getAllCustomers,
    changeStatus,
    create,
    update
};

function getAllCustomers(params) {
    return customerService.getAllCustomers(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function changeStatus(id, params) {
    return customerService.changeStatus(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function create(params) {
    return customerService.create(params)
        .then(
            resp => {
                message.success('Created');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(id, params) {
    return customerService.update(id, params)
        .then(
            resp => {
                message.success('Updated');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
