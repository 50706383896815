import React, { Component } from 'react'
import { Table, Tag, Input, Space, Button, Popconfirm, Tooltip } from 'antd';
import { SyncOutlined, SearchOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { jobAction } from '../../redux/_actions';
import AddNewJob from '../../components/Forms/Jobs/Add';
import UpdateJob from '../../components/Forms/Jobs/Update';
import { Breadcrumb } from 'antd';
import Loader from '../../components/Loader';
import moment from 'moment';

class Jobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {},
            selectedRecord: {},
            jobs: [],
            totalJobs: 0,
            jobsLoading: false,
            searchText: '',
            searchedColumn: '',
            page: 1,
            modal: {
                addForm: false
            }
        }
    }
    componentDidMount = () => {
        this.getAllJobs()
    }
    toggleModal = (key) => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, [key]: !modal[key] } })
    }
    toggleLoading = (key) => {
        const { loading } = this.state;
        this.setState({ loading: { ...loading, [key]: !loading[key] } })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
        this.getAllJobs({
            searchKey: dataIndex,
            searchValue: selectedKeys[0],
            page: 1
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        this.getAllJobs()
    };
    getAllJobs = async (params) => {
        try {
            this.setState({ jobsLoading: true });
            const resp = await jobAction.getAllJobs(params);
            this.setState({ jobsLoading: false, jobs: resp.jobs || [], totalJobs: resp.total });
        } catch (error) {
            this.setState({ jobsLoading: false });
        }

    }
    confirmStatusChange = async (id, params) => {
        try {
            this.toggleLoading('statusChange');
            await jobAction.changeStatus(id, params)
            this.getAllJobs();
            this.toggleLoading('statusChange');
        } catch (error) {
            this.toggleLoading('statusChange');
        }
    }
    onChangePage = (newPage) => {
        const { searchedColumn, searchText, page } = this.state;
        if (page != newPage) { // To prevent unwanted API calls
            const params = { page: newPage };
            if (searchedColumn && searchText) {
                params['searchKey'] = searchedColumn
                params['searchValue'] = searchText
            }
            this.getAllJobs(params);
            this.setState({ page: newPage })
        }
    }
    createAndList = async (params) => {
        try {
            this.toggleLoading('addJob');
            await jobAction.create(params)
            this.setState({
                modal: { ...this.state.modal, addForm: false },
                loading: { ...this.state.loading, addJob: false }
            });
            this.getAllJobs();
        } catch (error) {
            this.toggleLoading('addJob');
        }
    }
    updateAndList = async (params) => {
        try {
            this.toggleLoading('updateJob');
            await jobAction.update(this.state.selectedRecord.id, params)
            this.setState({
                modal: { ...this.state.modal, updateForm: false },
                loading: { ...this.state.loading, updateJob: false }
            });
            this.getAllJobs();
        } catch (error) {
            this.toggleLoading('updateJob');
        }
    }
    render() {
        const columns = [
            {
                title: 'Name of the position',
                dataIndex: 'jobTitle',
                key: 'jobTitle',
                ...this.getColumnSearchProps('jobTitle'),
            },
            {
                title: 'Location',
                dataIndex: 'location',
                key: 'location',
                ...this.getColumnSearchProps('location'),
                ellipsis: true,
            },
            {
                title: 'Details',
                dataIndex: 'jobDescription',
                key: 'jobDescription',
                ...this.getColumnSearchProps('jobDescription'),
                ellipsis: true,
            },
            {
                title: 'Date Uploaded',
                dataIndex: 'createdOn',
                key: 'createdOn',
                render: record => <>{moment(record).format('YYYY-MM-DD HH:mm')}</>
            },
            {
                title: 'Status',
                key: 'isActive',
                width: '15%',
                render: record =>
                    <Space size="middle">
                        <Popconfirm
                            title={`Are you sure want to ${record.isActive ? 'deactivate' : 'activate'} this job?`}
                            onConfirm={() => this.confirmStatusChange(record.id, {
                                status: record.isActive ? 'deactivate' : 'activate'
                            })}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tag color={record.isActive ? 'green' : 'red'}>{record.isActive ? 'ACTIVE' : 'DEACTIVATED'}</Tag> <SyncOutlined style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                    </Space>
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <EditOutlined
                            onClick={() => {
                                this.toggleModal('updateForm')
                                this.setState({ selectedRecord: record })
                            }} />
                        <Tooltip title="View applicants">
                            <EyeOutlined onClick={() => this.props.history.push(`/jobs/${record.id}/applicants`)} />
                        </Tooltip>
                    </Space>
                ),
            }
        ];
        console.log(this.state)
        return (
            <div>
                <Loader loading={this.state.loading.addJob || this.state.loading.updateJob || this.state.loading.statusChange} text={"Updating status"} />
                {this.state.modal.addForm && <AddNewJob
                    loading={this.state.loading.addJob}
                    onCreate={this.createAndList}
                    onCancel={() => this.toggleModal('addForm')}
                />}
                {this.state.modal.updateForm && <UpdateJob
                    loading={this.state.loading.updateJob}
                    initialValues={this.state.selectedRecord}
                    onCreate={(e) => {
                        this.updateAndList(e);
                        this.setState({ selectedRecord: {} })
                    }}
                    onCancel={() => {
                        this.toggleModal('updateForm');
                        this.setState({ selectedRecord: {} })
                    }}
                />}
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Jobs</Breadcrumb.Item>
                </Breadcrumb>
                <div className="add-new-btn">
                    <Button
                        type="primary"
                        onClick={() => {
                            this.toggleModal('addForm')
                        }}>
                        New Job
                    </Button>
                </div>
                <Table rowKey={'id'}
                    pagination={{
                        onChange: this.onChangePage,
                        total: this.state.totalJobs
                    }}
                    columns={columns}
                    loading={this.state.jobsLoading} bordered dataSource={this.state.jobs} />
            </div>
        )
    }
}

export default Jobs;
