import React, { Component } from 'react'
import { Table, Checkbox, Input, Space, Tag, Button, Popconfirm, Popover, Tooltip, size } from 'antd';
import { DeleteOutlined, PlusOutlined, SearchOutlined, EditOutlined, EnvironmentFilled, EyeOutlined, DownloadOutlined, FilterFilled } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import {
    inspectionAction, poleAction, optionAction, inspectionTypeAction,
    inspectionSubTypeAction, downloadAction, customerAction, inspectionCompanyAction
} from '../../redux/_actions';
import AddNewInspection from '../../components/Forms/Inspections/Add';
import UpdateInspection from '../../components/Forms/Inspections/Update';
import DownloadInspection from '../../components/Forms/Inspections/Download';
import FilterInspection from '../../components/Forms/Inspections/Filter';
import { Breadcrumb } from 'antd';
import _ from 'lodash';
import Loader from '../../components/Loader';
import MapView from '../../components/MapView';

class Inspections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {},
            selectedRecord: {},
            inspections: [],
            totalInspections: 0,
            inspectionsLoading: false,
            searchText: '',
            searchedColumn: '',
            photos: [],
            counties: [],
            inspectionTypes: [],
            inspectionSubTypes: [],
            locations: [],
            conditions: [],
            maintenances: [],
            weathers: [],
            customers: [],
            poles: [],
            inspectionCompanies: [],
            page: 1,
            modal: {
                addForm: false
            },
            filters: {}
        }
    }
    componentDidMount = () => {
        this.getAllInspections()
        this.getAllPoles()
        this.getAllInspectionSubTypes()
        this.getAllInspectionTypes()
        this.getAllLocations()
        this.getAllConditions()
        this.getAllMaintenances()
        this.getAllCustomers()
        this.getAllWeathers()
        this.getAllInspectionCompanies()
    }
    toggleModal = (key) => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, [key]: !modal[key] } })
    }
    toggleLoading = (key) => {
        const { loading } = this.state;
        this.setState({ loading: { ...loading, [key]: !loading[key] } })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
        this.getAllInspections({
            searchKey: dataIndex,
            searchValue: selectedKeys[0],
            page: 1
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        this.getAllInspections()
    };
    getAllInspections = async (params) => {
        try {
            this.setState({ inspectionsLoading: true });
            if (!_.isEmpty(this.state.filters)) Object.assign(params, this.state.filters)
            const resp = await inspectionAction.getAllInspections(params);
            this.setState({ inspectionsLoading: false, inspections: resp.inspections || [], totalInspections: resp.total });
        } catch (error) {
            this.setState({ inspectionsLoading: false });
        }
    }
    getAllPoles = async (params) => {
        try {
            this.toggleLoading('polesLoading');
            const resp = await poleAction.getAllPoles(params);
            this.setState({ loading: { ...this.state.loading, polesLoading: false }, poles: resp.poles || [] });
        } catch (error) {
            this.toggleLoading('polesLoading');
        }
    }
    getAllInspectionCompanies = async (params) => {
        try {
            this.toggleLoading('inspectionCompaniesLoading');
            const resp = await inspectionCompanyAction.getAllInspectionCompanies(params);
            this.setState({ loading: { ...this.state.loading, inspectionCompaniesLoading: false }, inspectionCompanies: resp.inspectionCompanies || [] });
        } catch (error) {
            this.toggleLoading('inspectionCompaniesLoading');
        }
    }
    getAllInspectionTypes = async (params) => {
        try {
            this.toggleLoading('inspectionTypesLoading');
            const resp = await inspectionTypeAction.getAllInspectionTypes(params);
            this.setState({ loading: { ...this.state.loading, inspectionTypesLoading: false }, inspectionTypes: resp.inspectionTypes || [] });
        } catch (error) {
            this.toggleLoading('inspectionTypesLoading');
        }
    }
    getAllInspectionSubTypes = async (params) => {
        try {
            this.toggleLoading('inspectionSubTypesLoading');
            const resp = await inspectionSubTypeAction.getAllInspectionSubTypes(params);
            this.setState({ loading: { ...this.state.loading, inspectionSubTypesLoading: false }, inspectionSubTypes: resp.inspectionSubtypes || [] });
        } catch (error) {
            this.toggleLoading('inspectionSubTypesLoading');
        }
    }
    getAllLocations = async (params) => {
        try {
            this.toggleLoading('locationsLoading');
            const resp = await optionAction.getAllOptions('location', params);
            this.setState({ loading: { ...this.state.loading, locationsLoading: false }, locations: resp.options || [] });
        } catch (error) {
            this.toggleLoading('locationsLoading');
        }
    }
    getAllConditions = async (params) => {
        try {
            this.toggleLoading('conditionsLoading');
            const resp = await optionAction.getAllOptions('condition', params);
            this.setState({ loading: { ...this.state.loading, conditionsLoading: false }, conditions: resp.options || [] });
        } catch (error) {
            this.toggleLoading('conditionsLoading');
        }
    }
    getAllMaintenances = async (params) => {
        try {
            this.toggleLoading('maintenancesLoading');
            const resp = await optionAction.getAllOptions('maintenance', params);
            this.setState({ loading: { ...this.state.loading, maintenancesLoading: false }, maintenances: resp.options || [] });
        } catch (error) {
            this.toggleLoading('maintenancesLoading');
        }
    }
    getAllWeathers = async (params) => {
        try {
            this.toggleLoading('weathersLoading');
            const resp = await optionAction.getAllOptions('weather', params);
            this.setState({ loading: { ...this.state.loading, weathersLoading: false }, weathers: resp.options || [] });
        } catch (error) {
            this.toggleLoading('weathersLoading');
        }
    }
    getAllCustomers = async (params) => {
        try {
            this.toggleLoading('customersLoading');
            const resp = await customerAction.getAllCustomers(params);
            this.setState({ loading: { ...this.state.loading, customersLoading: false }, customers: resp.customers || [] });
        } catch (error) {
            this.toggleLoading('customersLoading');
        }
    }
    onChangePage = (newPage) => {
        const { searchedColumn, searchText, page } = this.state;
        if (page != newPage) { // To prevent unwanted API calls
            const params = { page: newPage };
            if (searchedColumn && searchText) {
                params['searchKey'] = searchedColumn
                params['searchValue'] = searchText
            }
            this.getAllInspections(params);
            this.setState({ page: newPage })
        }
    }
    createAndList = async (params) => {
        try {
            this.toggleLoading('addInspection');
            await inspectionAction.create({ ...params })
            this.setState({
                modal: { ...this.state.modal, addForm: false },
                loading: { ...this.state.loading, addInspection: false }
            });
            this.getAllInspections();
        } catch (error) {
            this.toggleLoading('addInspection');
        }
    }
    updateAndList = async (params) => {
        try {
            this.toggleLoading('updateInspection');
            await inspectionAction.update(this.state.selectedRecord.id, { ...params })
            this.setState({
                modal: { ...this.state.modal, updateForm: false },
                loading: { ...this.state.loading, updateInspection: false }
            });
            this.getAllInspections();
        } catch (error) {
            this.toggleLoading('updateInspection');
        }
    }
    deleteAndList = async (params) => {
        try {
            this.toggleLoading('deleteInspection');
            await inspectionAction.deleteItem(params)
            this.setState({
                loading: { ...this.state.loading, deleteInspection: false }
            });
            this.getAllInspections();
        } catch (error) {
            this.toggleLoading('deleteInspection');
        }
    }
    onSearchFormFields = (key, value) => {
        switch (key) {
            case 'poleId':
                this.getAllPoles({ searchKey: 'poleNumber', searchValue: value })
                break;
            case 'inspectionTypeCode':
                this.getAllInspectionTypes({ searchKey: 'code', searchValue: value })
                break;
            case 'inspectionSubTypeCode':
                this.getAllInspectionSubTypes({ searchKey: 'code', searchValue: value })
                break;
            case 'maintenanceCode':
                this.getAllMaintenances({ searchKey: 'code', searchValue: value })
                break;
            case 'locationCode':
                this.getAllLocations({ searchKey: 'code', searchValue: value })
                break;
            case 'conditionCode':
                this.getAllConditions({ searchKey: 'code', searchValue: value })
                break;
            case 'weatherCode':
                this.getAllWeathers({ searchKey: 'code', searchValue: value })
                break;
            case 'customerId':
                this.getAllCustomers({ searchKey: 'customerName', searchValue: value })
                break;
            case 'inspectionCompanyId':
                this.getAllInspectionCompanies({ searchKey: 'inspectionCompanyId', searchValue: value })
                break;
            default:
                break;
        }
    }
    onClickDownload = async (params) => {
        try {
            this.toggleLoading('downloadForm');
            const { filters } = this.state;
            await downloadAction.downloadInspections({ ...params, ...filters });
            this.setState({
                modal: { ...this.state.modal, downloadForm: false },
                loading: { ...this.state.loading, downloadForm: false }
            });
        } catch (error) {
            this.toggleLoading('downloadForm');
        }
    }
    onClickFilter = async (filters) => {
        try {
            console.log(filters)
            this.toggleLoading('filterForm');
            this.getAllInspections(filters)
            this.setState({
                filters,
                modal: { ...this.state.modal, filterForm: false },
                loading: { ...this.state.loading, filterForm: false }
            });
        } catch (error) {
            this.toggleLoading('filterForm');
        }
    }
    onCloseFilter = (key) => {
        const { filters } = this.state;
        if (key === 'from' || key === 'to') {
            delete filters['from'];
            delete filters['to'];
        } else {
            delete filters[key];
        }
        this.setState({ filters })
        this.getAllInspections(filters)
    }
    render() {
        const columns = [
            {
                title: 'Pole #',
                key: 'pole',
                render: record => <>{record.poleId}</>,
            },
            {
                title: 'Direction',
                align: 'center',
                key: 'directionCode',
                render: record => <>{record.pole.directionCode}</>,
            },
            {
                title: 'Year',
                align: 'center',
                key: 'year',
                render: record => <>{record.pole.year}</>,
            },
            {
                title: 'Internal',
                key: 'internal',
                align: 'center',
                render: record => <>{<Checkbox disabled checked={record.internal} />}</>,
            },
            {
                title: 'Species',
                align: 'center',
                key: 'speciesCode',
                render: record => <>{record.pole.speciesCode}</>,
            },
            {
                title: 'Original Treatment',
                align: 'center',
                key: 'originalTreatmentCode',
                render: record => <>{record.pole.originalTreatmentCode}</>,
            },
            {
                title: 'Height',
                align: 'center',
                key: 'heightCode',
                render: record => <>{record.pole.heightCode}</>,
            },
            {
                title: 'Class',
                align: 'center',
                key: 'classCode',
                render: record => <>{record.pole.classCode}</>,
            },
            {
                title: 'Manufacturer',
                align: 'center',
                key: 'manufacturerCode',
                render: record => <>{record.pole.manufacturerCode}</>,
            },
            {
                title: 'Location',
                key: 'location',
                align: 'center',
                render: record => <Popover content={<MapView location={{ lat: record.lat, lng: record.lng }} />} title="Location" trigger="click">
                    <EnvironmentFilled />
                </Popover>
            },
            {
                title: 'Created By',
                dataIndex: 'createdUser',
                key: 'createdUser',
                render: record => <span>{record.name}</span>,
            },
            {
                title: 'Created At',
                dataIndex: 'createdOn',
                key: 'createdOn',
                render: record => <>{moment(record).format('YYYY-MM-DD HH:mm')}</>,
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <EditOutlined
                            onClick={() => {
                                this.toggleModal('updateForm')
                                this.setState({ selectedRecord: record })
                            }} />
                        <Popconfirm
                            title={`Are you sure want to delete this inspection?`}
                            onConfirm={() => this.deleteAndList(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                        <Tooltip title="View inspection details">
                            <EyeOutlined onClick={() => this.props.history.push(`/inspections/${record.id}`)} />
                        </Tooltip>
                    </Space>
                ),
            }
        ];
        return (
            <div>
                <Loader loading={this.state.loading.addInspection || this.state.loading.updateInspection || this.state.loading.deleteInspection} text={"Updating status"} />
                {this.state.modal.addForm &&
                    <AddNewInspection
                        loading={this.state.loading}
                        onCreate={this.createAndList}
                        poles={this.state.poles}
                        inspectionCompanies={this.state.inspectionCompanies}
                        inspectionTypes={this.state.inspectionTypes}
                        inspectionSubTypes={this.state.inspectionSubTypes}
                        locations={this.state.locations}
                        conditions={this.state.conditions}
                        maintenances={this.state.maintenances}
                        weathers={this.state.weathers}
                        onSearch={this.onSearchFormFields}
                        onCancel={() => this.toggleModal('addForm')}
                    />
                }
                {this.state.modal.updateForm &&
                    <UpdateInspection
                        loading={this.state.loading}
                        initialValues={this.state.selectedRecord}
                        poles={this.state.poles}
                        inspectionCompanies={this.state.inspectionCompanies}
                        inspectionTypes={this.state.inspectionTypes}
                        inspectionSubTypes={this.state.inspectionSubTypes}
                        locations={this.state.locations}
                        conditions={this.state.conditions}
                        maintenances={this.state.maintenances}
                        weathers={this.state.weathers}
                        onSearch={this.onSearchFormFields}
                        onCreate={(e) => {
                            this.updateAndList(e);
                            this.setState({ selectedRecord: {} })
                        }}
                        onCancel={() => {
                            this.toggleModal('updateForm');
                            this.setState({ selectedRecord: {} })
                        }}
                    />
                }
                {this.state.modal.downloadForm &&
                    <DownloadInspection
                        loading={this.state.loading}
                        onSubmit={(e) => {
                            this.onClickDownload(e)
                        }}
                        onCancel={() => {
                            this.toggleModal('downloadForm');
                        }}
                    />
                }
                {this.state.modal.filterForm &&
                    <FilterInspection
                        loading={this.state.loading}
                        customers={this.state.customers}
                        onSearch={this.onSearchFormFields}
                        onSubmit={this.onClickFilter}
                        onCancel={() => {
                            this.setState({ filters: {} })
                            this.toggleModal('filterForm');
                        }}
                    />
                }
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Inspections</Breadcrumb.Item>
                </Breadcrumb>
                <div className="row">
                    <div className="col-md-9">
                        <div className="filter-container">
                            {Object.keys(this.state.filters).map(item => <Tag closable color="processing" onClose={() => this.onCloseFilter(item)}>
                                {item.toUpperCase(this)} : {this.state.filters[item]}
                            </Tag>)}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="add-new-btn">
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    this.toggleModal('addForm')
                                }}>
                                New Inspection
                            </Button>
                            <Button
                                type="primary"
                                style={{ marginLeft: '10px' }}
                                icon={<FilterFilled />}
                                onClick={() => {
                                    this.toggleModal('filterForm')
                                }}>
                                Filter
                            </Button>
                            <Tooltip title="Only customer based download available. Please use filter for selecting customer">
                                <Button type="primary" style={{ marginLeft: '10px' }} icon={<DownloadOutlined />}
                                    disabled={!this.state.filters.customerId || !this.state.inspections.length}
                                    onClick={() => {
                                        this.toggleModal('downloadForm')
                                    }}>
                                    Download
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <Table rowKey={'id'}
                    pagination={{
                        onChange: this.onChangePage,
                        total: this.state.totalInspections
                    }}
                    columns={columns}
                    loading={this.state.inspectionsLoading} bordered dataSource={this.state.inspections} />
            </div >
        )
    }
}

export default Inspections;
