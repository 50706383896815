import { userService } from '../_services';
import { message } from 'antd';

export const userAction = {
    create,
    update,
    getAllUsers,
    changeStatus,
    changeAdminStatus,
    clearPassword,
    updatePassword,
    changePassword
};

function create(params) {
    return userService.create(params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(id, params) {
    return userService.update(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function getAllUsers(params) {
    return userService.getAllUsers(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function changeStatus(id, params) {
    return userService.changeStatus(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function changeAdminStatus(id, params) {
    return userService.changeAdminStatus(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function clearPassword(id) {
    return userService.clearPassword(id)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function updatePassword(id, params) {
    return userService.updatePassword(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function changePassword(params) {
    return userService.changePassword(params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
