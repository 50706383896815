import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import { Form, Input, Button, Checkbox, Spin } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { authActions } from '../../redux/_actions';
import { withRouter } from 'react-router-dom';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = () => {
    this.props.logout();
  }
  handleSubmit = async (values) => {
    try {
      const { username, password, name } = values;
      this.setState({ loading: true });
      await this.props.signup({ username, password, name });
      this.setState({ loading: false });
      setTimeout(() => {
        this.props.history.push('/login')
      }, 3000);
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  render() {
    const { loading } = this.state;
    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          {loading &&
            <Loader />
            ||
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card-group mb-0">
                  <div className="card p-2">
                    <div className="card-block text-center">
                      <span className="login-logo"></span>
                      <p className="text-muted">Create account</p>
                      <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={this.handleSubmit}
                      >
                        <Form.Item
                          name="name"
                          rules={[{ required: true, message: 'Please provide your name!' }]}
                        >
                          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Name" />
                        </Form.Item>
                        <Form.Item
                          name="username"
                          rules={[{ required: true, message: 'Please provide your username!' }]}
                        >
                          <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          rules={[{ required: true, message: 'Please provide your password!' }]}
                        >
                          <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit" className="login-form-button">
                            Sign up
                        </Button>
                        </Form.Item>
                        <Form.Item>
                          <Link to="/login" className="btn btn-link">Already have an account? Signup</Link>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}


function mapState(state) {
  const { loading } = state.signup;
  return { loading };
}

const actionCreators = {
  signup: authActions.signup,
  logout: authActions.logout
};

export default withRouter(connect(mapState, actionCreators)(Signup));