import { appConstants } from '../../_constants';
let INITIAL_STATE = {
    user: {},
    modal: {
        assignVehicle: false
    }
}
function changeModalVisibility(state, action, type) {
    return {
        user: state.user,
        modal: {
            ...state.modal,
            [action.params]: type
        }
    }
}
export function appState(state = INITIAL_STATE, action) {
    switch (action.type) {
        case appConstants.MODAL_WINDOW_SHOW:
            return changeModalVisibility(state, action, true)
        case appConstants.MODAL_WINDOW_HIDE:
            return changeModalVisibility(state, action, false)
        case appConstants.MODAL_WINDOW_TOGGLE:
            return changeModalVisibility(state, action, !state.modal[action.params])
        default:
            return state
    }
}