import { inspectionTypeService } from '../_services';
import { message } from 'antd';

export const inspectionTypeAction = {
    getAllInspectionTypes,
    changeStatus,
    create,
    update,
    deleteItem
};

function getAllInspectionTypes(params) {
    return inspectionTypeService.getAllInspectionTypes(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function changeStatus(id, params) {
    return inspectionTypeService.changeStatus(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function create(params) {
    return inspectionTypeService.create(params)
        .then(
            resp => {
                message.success('Created');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(id, params) {
    return inspectionTypeService.update(id, params)
        .then(
            resp => {
                message.success('Updated');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function deleteItem(id) {
    return inspectionTypeService.delete(id)
        .then(
            resp => {
                message.success('Deleted');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
