import React, { Component } from 'react'
import { Table, Tag, Input, Space, Button, Popconfirm, Popover, Tooltip } from 'antd';
import { DeleteOutlined, SearchOutlined, EditOutlined, EnvironmentFilled, EyeOutlined, FilterFilled } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { poleAction, optionAction, powerlineAction, customerAction, countyAction } from '../../redux/_actions';
import AddNewPole from '../../components/Forms/Poles/Add';
import UpdatePole from '../../components/Forms/Poles/Update';
import FilterPoles from '../../components/Forms/Poles/Filter';
import { Breadcrumb } from 'antd';
import Loader from '../../components/Loader';
import MapView from '../../components/MapView';
import _ from 'lodash';

class Poles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {},
            selectedRecord: {},
            poles: [],
            totalPoles: 0,
            polesLoading: false,
            searchText: '',
            searchedColumn: '',
            powerlines: [],
            classCodes: [],
            manufacturerCodes: [],
            sideCodes: [],
            directionCodes: [],
            speciesCodes: [],
            heights: [],
            counties: [],
            originalTreatmentCodes: [],
            page: 1,
            modal: {
                addForm: false
            },
            filters: {}
        }
    }
    componentDidMount = () => {
        this.getAllPoles()
        this.getAllPowerlines()
        this.getAllClassCodes()
        this.getAllManufacturerCodes()
        this.getAllSideCodes()
        this.getAllDirectionCodes()
        this.getAllSpeciesCodes()
        this.getAllOriginalTreatmentCodes()
        this.getAllHeights()
        this.getAllCustomers()
        this.getAllCounties()
    }
    toggleModal = (key) => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, [key]: !modal[key] } })
    }
    toggleLoading = (key) => {
        const { loading } = this.state;
        this.setState({ loading: { ...loading, [key]: !loading[key] } })
    }
    getAllCounties = async (params) => {
        try {
            this.toggleLoading('countiesLoading');
            const resp = await countyAction.getAllCounties(params);
            this.setState({ loading: { ...this.state.loading, countiesLoading: false }, counties: resp.counties || [] });
        } catch (error) {
            this.toggleLoading('countiesLoading');
        }
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
        this.getAllPoles({
            searchKey: dataIndex,
            searchValue: selectedKeys[0],
            page: 1
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        this.getAllPoles()
    };
    getAllPoles = async (params) => {
        try {
            this.setState({ polesLoading: true });
            if (!_.isEmpty(this.state.filters)) Object.assign(params, this.state.filters)
            const resp = await poleAction.getAllPoles(params);
            this.setState({ polesLoading: false, poles: resp.poles || [], totalPoles: resp.total });
        } catch (error) {
            this.setState({ polesLoading: false });
        }

    }
    getAllPowerlines = async (params) => {
        try {
            this.toggleLoading('powerlinesLoading');
            const resp = await powerlineAction.getAllPowerlines(params);
            this.setState({ loading: { ...this.state.loading, powerlinesLoading: false }, powerlines: resp.powerlines || [] });
        } catch (error) {
            this.toggleLoading('powerlinesLoading');
        }
    }
    getAllClassCodes = async (params) => {
        try {
            this.toggleLoading('classCodesLoading');
            const resp = await optionAction.getAllOptions('class', params);
            this.setState({ loading: { ...this.state.loading, classCodesLoading: false }, classCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('classCodesLoading');
        }
    }
    getAllManufacturerCodes = async (params) => {
        try {
            this.toggleLoading('manufacturerCodesLoading');
            const resp = await optionAction.getAllOptions('manufacturer', params);
            this.setState({ loading: { ...this.state.loading, manufacturerCodesLoading: false }, manufacturerCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('manufacturerCodesLoading');
        }
    }
    getAllSideCodes = async (params) => {
        try {
            this.toggleLoading('sideCodesLoading');
            const resp = await optionAction.getAllOptions('side', params);
            this.setState({ loading: { ...this.state.loading, sideCodesLoading: false }, sideCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('sideCodesLoading');
        }
    }
    getAllDirectionCodes = async (params) => {
        try {
            this.toggleLoading('directionCodesLoading');
            const resp = await optionAction.getAllOptions('direction', params);
            this.setState({ loading: { ...this.state.loading, directionCodesLoading: false }, directionCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('directionCodesLoading');
        }
    }
    getAllSpeciesCodes = async (params) => {
        try {
            this.toggleLoading('speciesCodesLoading');
            const resp = await optionAction.getAllOptions('species', params);
            this.setState({ loading: { ...this.state.loading, speciesCodesLoading: false }, speciesCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('speciesCodesLoading');
        }
    }
    getAllHeights = async (params) => {
        try {
            this.toggleLoading('heightsLoading');
            const resp = await optionAction.getAllOptions('height', params);
            this.setState({ loading: { ...this.state.loading, heightsLoading: false }, heights: resp.options || [] });
        } catch (error) {
            this.toggleLoading('heightsLoading');
        }
    }
    getAllOriginalTreatmentCodes = async (params) => {
        try {
            this.toggleLoading('originalTreatmentCodesLoading');
            const resp = await optionAction.getAllOptions('originalTreatment', params);
            this.setState({ loading: { ...this.state.loading, originalTreatmentCodesLoading: false }, originalTreatmentCodes: resp.options || [] });
        } catch (error) {
            this.toggleLoading('originalTreatmentCodesLoading');
        }
    }
    onChangePage = (newPage) => {
        const { searchedColumn, searchText, page } = this.state;
        if (page != newPage) { // To prevent unwanted API calls
            const params = { page: newPage };
            if (searchedColumn && searchText) {
                params['searchKey'] = searchedColumn
                params['searchValue'] = searchText
            }
            this.getAllPoles(params);
            this.setState({ page: newPage })
        }
    }
    createAndList = async (params) => {
        try {
            this.toggleLoading('addPole');
            await poleAction.create({ ...params })
            this.setState({
                modal: { ...this.state.modal, addForm: false },
                loading: { ...this.state.loading, addPole: false }
            });
            this.getAllPoles();
        } catch (error) {
            this.toggleLoading('addPole');
        }
    }
    updateAndList = async (params) => {
        try {
            this.toggleLoading('updatePole');
            await poleAction.update(this.state.selectedRecord.id, { ...params })
            this.setState({
                modal: { ...this.state.modal, updateForm: false },
                loading: { ...this.state.loading, updatePole: false }
            });
            this.getAllPoles();
        } catch (error) {
            this.toggleLoading('updatePole');
        }
    }
    deleteAndList = async (params) => {
        try {
            this.toggleLoading('deletePole');
            await poleAction.deleteItem(params)
            this.setState({
                loading: { ...this.state.loading, deletePole: false }
            });
            this.getAllPoles();
        } catch (error) {
            this.toggleLoading('deletePole');
        }
    }
    onSearchFormFields = (key, value) => {
        switch (key) {
            case 'powerlineId':
                this.getAllPowerlines({ searchKey: 'lineName', searchValue: value })
                break;
            case 'classCode':
                this.getAllClassCodes({ searchKey: 'code', searchValue: value })
                break;
            case 'manufacturerCode':
                this.getAllManufacturerCodes({ searchKey: 'code', searchValue: value })
                break;
            case 'sideCode':
                this.getAllSideCodes({ searchKey: 'code', searchValue: value })
                break;
            case 'directionCode':
                this.getAllDirectionCodes({ searchKey: 'code', searchValue: value })
                break;
            case 'speciesCode':
                this.getAllSpeciesCodes({ searchKey: 'code', searchValue: value })
                break;
            case 'originalTreatmentCode':
                this.getAllOriginalTreatmentCodes({ searchKey: 'code', searchValue: value })
                break;
            case 'heightCode':
                this.getAllHeights({ searchKey: 'code', searchValue: value })
                break;
            case 'customerId':
                this.getAllCustomers({ searchKey: 'customerName', searchValue: value })
                break;
            case 'countyId':
                this.getAllCounties({ searchKey: 'county', searchValue: value })
                break;
            default:
                break;
        }
    }
    onClickFilter = async (filters) => {
        try {
            console.log(filters)
            this.toggleLoading('filterForm');
            this.getAllPoles(filters)
            this.setState({
                filters,
                modal: { ...this.state.modal, filterForm: false },
                loading: { ...this.state.loading, filterForm: false }
            });
        } catch (error) {
            this.toggleLoading('filterForm');
        }
    }
    onCloseFilter = (key) => {
        const { filters } = this.state;
        if (key === 'from' || key === 'to') {
            delete filters['from'];
            delete filters['to'];
        } else {
            delete filters[key];
        }
        this.setState({ filters })
        this.getAllPoles(filters)
    }
    getAllCustomers = async (params) => {
        try {
            this.toggleLoading('customersLoading');
            const resp = await customerAction.getAllCustomers(params);
            this.setState({ loading: { ...this.state.loading, customersLoading: false }, customers: resp.customers || [] });
        } catch (error) {
            this.toggleLoading('customersLoading');
        }
    }
    render() {
        const columns = [
            {
                title: 'Pole Number',
                dataIndex: 'poleNumber',
                key: 'poleNumber',
            },
            {
                title: 'polePosition',
                dataIndex: 'polePosition',
                key: 'polePosition',
                ...this.getColumnSearchProps('polePosition'),
            },
            {
                title: 'Township',
                dataIndex: 'township',
                key: 'township',
                ...this.getColumnSearchProps('township'),
            },
            {
                title: 'Range',
                dataIndex: 'range',
                key: 'range',
                ...this.getColumnSearchProps('range'),
            },
            {
                title: 'Section',
                dataIndex: 'section',
                key: 'section',
                ...this.getColumnSearchProps('section'),
            },
            {
                title: 'Location',
                key: 'location',
                align: 'center',
                render: record => <Popover content={<MapView location={{ lat: record.lat, lng: record.lng }} />} title="Location" trigger="click">
                    <EnvironmentFilled />
                </Popover>
            },
            {
                title: 'Created By',
                dataIndex: 'createdUser',
                key: 'createdUser',
                render: record => <span>{record.name}</span>,
            },
            {
                title: 'Updated By',
                dataIndex: 'updatedUser',
                key: 'updatedUser',
                render: record => <span>{record.name}</span>,
            },
            {
                title: 'County',
                dataIndex: 'county',
                key: 'county',
                render: record => <span>{record.county}</span>,
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
                ...this.getColumnSearchProps('zip'),
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <EditOutlined
                            onClick={() => {
                                this.toggleModal('updateForm')
                                this.setState({ selectedRecord: record })
                            }} />
                        <Popconfirm
                            title={`Are you sure want to delete this pole?`}
                            onConfirm={() => this.deleteAndList(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                        <Tooltip title="View pole details">
                            <EyeOutlined onClick={() => this.props.history.push(`/poles/${record.id}`)} />
                        </Tooltip>
                    </Space>
                ),
            }
        ];
        console.log(this.state)
        return (
            <div>
                <Loader loading={this.state.loading.addPole || this.state.loading.updatePole || this.state.loading.deletePole} text={"Updating status"} />
                {this.state.modal.addForm && <AddNewPole
                    loading={this.state.loading}
                    counties={this.state.counties}
                    onCreate={this.createAndList}
                    powerlines={this.state.powerlines}
                    classCodes={this.state.classCodes}
                    manufacturerCodes={this.state.manufacturerCodes}
                    sideCodes={this.state.sideCodes}
                    directionCodes={this.state.directionCodes}
                    speciesCodes={this.state.speciesCodes}
                    originalTreatmentCodes={this.state.originalTreatmentCodes}
                    heights={this.state.heights}
                    onSearch={this.onSearchFormFields}
                    onCancel={() => this.toggleModal('addForm')}
                />}
                {this.state.modal.updateForm && <UpdatePole
                    counties={this.state.counties}
                    loading={this.state.loading}
                    initialValues={this.state.selectedRecord}
                    powerlines={this.state.powerlines}
                    classCodes={this.state.classCodes}
                    manufacturerCodes={this.state.manufacturerCodes}
                    sideCodes={this.state.sideCodes}
                    directionCodes={this.state.directionCodes}
                    speciesCodes={this.state.speciesCodes}
                    originalTreatmentCodes={this.state.originalTreatmentCodes}
                    heights={this.state.heights}
                    onSearch={this.onSearchFormFields}
                    onCreate={(e) => {
                        this.updateAndList(e);
                        this.setState({ selectedRecord: {} })
                    }}
                    onCancel={() => {
                        this.toggleModal('updateForm');
                        this.setState({ selectedRecord: {} })
                    }}
                />}
                {this.state.modal.filterForm &&
                    <FilterPoles
                        loading={this.state.loading}
                        customers={this.state.customers}
                        onSearch={this.onSearchFormFields}
                        onSubmit={(e) => {
                            this.onClickFilter(e)
                        }}
                        onCancel={() => {
                            this.setState({ filters: {} })
                            this.toggleModal('filterForm');
                        }}
                    />
                }
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Poles</Breadcrumb.Item>
                </Breadcrumb>
                <div className="row">
                    <div className="col-md-9">
                        <div className="filter-container">
                            {Object.keys(this.state.filters).map(item => <Tag closable color="processing" onClose={() => this.onCloseFilter(item)}>
                                {item.toUpperCase(this)} : {this.state.filters[item]}
                            </Tag>)}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="add-new-btn">
                            <Button
                                type="primary"
                                onClick={() => {
                                    this.toggleModal('addForm')
                                }}>
                                New Pole
                            </Button>
                            <Button
                                type="primary"
                                style={{ marginLeft: '10px' }}
                                icon={<FilterFilled />}
                                onClick={() => {
                                    this.toggleModal('filterForm')
                                }}>
                                Filter
                            </Button>
                        </div>
                    </div>
                </div>
                <Table rowKey={'id'}
                    pagination={{
                        onChange: this.onChangePage,
                        total: this.state.totalPoles
                    }}
                    columns={columns}
                    loading={this.state.polesLoading} bordered dataSource={this.state.poles} />
            </div>
        )
    }
}

export default Poles;
