import axios from '../_client/axios';

export const userService = {
    create: async (params) => {
        try {
            const response = await axios.post(`/user`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    update: async (id, params) => {
        try {
            const response = await axios.put(`/user/${id}`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    getAllUsers: async (params) => {
        try {
            const response = await axios.get(`/user`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    changeStatus: async (id, params) => {
        try {
            const response = await axios.put(`/user/${id}/status`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    changeAdminStatus: async (id, params) => {
        try {
            const response = await axios.put(`/user/${id}/admin`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    clearPassword: async (id) => {
        try {
            const response = await axios.put(`/user/${id}/clear_password`);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    updatePassword: async (id, params) => {
        try {
            const response = await axios.put(`/user/${id}/password`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    changePassword: async (params) => {
        try {
            const response = await axios.put(`/user/password`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    }
};