import axios from '../_client/axios';

export const photoService = {
    getAll: async (params) => {
        try {
            const response = await axios.get(`/photo`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    delete: async (id) => {
        try {
            const response = await axios.delete(`/photo/${id}`);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    create: async (params) => {
        try {
            const response = await axios.post(`/photo`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    update: async (id, params) => {
        try {
            const response = await axios.put(`/photo/${id}`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    }
};