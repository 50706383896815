import React from 'react';
import { Button, Modal, Form, Input, Select, Space, InputNumber, DatePicker, Switch } from 'antd';
import moment from 'moment';
import utils from '../../../utils';
import { LinkOutlined } from '@ant-design/icons';

const { Option } = Select;
const UpdatePole = ({ onCreate, onCancel, loading,
    powerlines, classCodes, manufacturerCodes,
    sideCodes, directionCodes, speciesCodes, heights,
    originalTreatmentCodes, onSearch, initialValues, counties }) => {
    const [form] = Form.useForm();
    function handleOk() {
        form
            .validateFields()
            .then((values) => {
                utils.removeEmptyOrNull(values)
                onCreate({ ...values, year: parseInt(moment(values.year).format('YYYY')) });
                form.resetFields();
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    function handleCancel() {
        onCancel()
        form.resetFields();
    }
    console.log("loading")
    return (
        <Modal
            visible={true}
            title="Update pole"
            className={'max-width-modal'}
            onCancel={handleCancel}
            onOk={handleOk}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading.updatePole} onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    ...initialValues,
                    year: moment(initialValues.year)
                }}
            >
                <Form.Item
                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    name="powerlineId"
                    label="Power line"
                    rules={
                        [{
                            required: true,
                            message: 'Please select the powerline !',
                        }]
                    }
                >
                    <Select placeholder="Please select the powerline"
                        loading={loading.powerlinesLoading}
                        showSearch filterOption={false}
                        onSearch={(e) => onSearch('powerlineId', e)}>
                        {powerlines.map((item, key) =>
                            <Option key={key} value={item.id}>
                                {item.id}
                            </Option>)}
                    </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        name="poleNumber"
                        label="Pole Number"
                        rules={
                            [{
                                required: true,
                                message: 'Please input the pole number !',
                            }]
                        }
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="polePosition"
                        label="Pole Position"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        name="year"
                        label="Year"
                        rules={
                            [{
                                required: true,
                                message: 'Please select the year !',
                            }]
                        }
                    >
                        <DatePicker style={{ width: '100%' }} picker="year" />
                    </Form.Item>
                    <Form.Item
                        name="estimated"
                        label="Estimated"
                        valuePropName="checked"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[]}
                    >
                        <Switch defaultChecked />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        name="heightCode"
                        label="Height"
                        rules={
                            [{
                                required: true,
                                message: 'Please input the height !',
                            }]
                        }
                    >
                        <Select placeholder="Please select the powerline"
                            loading={loading.heightCodeLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('heightCode', e)}>
                            {heights.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="classCode"
                        label="Class"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={
                            [{
                                required: true,
                                message: 'Please select the class !',
                            }]
                        }
                    >
                        <Select placeholder="Please select the class"
                            loading={loading.classCodesLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('classCode', e)}>
                            {classCodes.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        name="township"
                        label="Township"
                        rules={
                            [{
                                required: true,
                                message: 'Please input the township !',
                            }]
                        }
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="manufacturerCode"
                        label="Manufacturer"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={
                            [{
                                required: true,
                                message: 'Please select the Manufacturer !',
                            }]
                        }
                    >
                        <Select placeholder="Please select the Manufacturer"
                            loading={loading.manufacturerCodesLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('manufacturerCode', e)}>
                            {manufacturerCodes.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        name="range"
                        label="Range"
                        rules={
                            [{
                                required: true,
                                message: 'Please input the range !',
                            }]
                        }
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="section"
                        label="Section"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={
                            [{
                                required: true,
                                message: 'Please input the Section !',
                            }]
                        }
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        name="sideCode"
                        label="Side"
                        rules={
                            [{
                                required: true,
                                message: 'Please select the side !',
                            }]
                        }
                    >
                        <Select placeholder="Please select the side"
                            loading={loading.sideCodesLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('sideCode', e)}>
                            {sideCodes.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="directionCode"
                        label="Direction"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={
                            [{
                                required: true,
                                message: 'Please select the direction !',
                            }]
                        }
                    >
                        <Select placeholder="Please select the direction"
                            loading={loading.directionCodesLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('directionCode', e)}>
                            {directionCodes.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        name="speciesCode"
                        label="Species"
                        rules={
                            [{
                                required: true,
                                message: 'Please select the species !',
                            }]
                        }
                    >
                        <Select placeholder="Please select the species"
                            loading={loading.speciesCodesLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('speciesCode', e)}>
                            {speciesCodes.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="originalTreatmentCode"
                        label="Original Treatment"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={
                            [{
                                required: true,
                                message: 'Please select the original treatment !',
                            }]
                        }
                    >
                        <Select placeholder="Please select the original treatmentCode"
                            loading={loading.originalTreatmentCodesLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('originalTreatmentCode', e)}>
                            {originalTreatmentCodes.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        label="Latitude"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <Space>
                            <Form.Item
                                name="lat"
                                noStyle
                                rules={[{
                                    required: true,
                                    message: 'Please input the longitude!',
                                }]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <a href="https://www.google.com/maps" target="_blank" rel="noopener" style={{ paddingLeft: '10px' }}><LinkOutlined /></a>
                        </Space>
                    </Form.Item>
                    <Form.Item
                        label="Longitude"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                    >
                        <Space>
                            <Form.Item
                                name="lng"
                                noStyle
                                rules={[{
                                    required: true,
                                    message: 'Please input the longitude!',
                                }]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <a href="https://www.google.com/maps" target="_blank" rel="noopener" style={{ paddingLeft: '10px' }}><LinkOutlined /></a>
                        </Space>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    name="countyId"
                    label="County"
                    rules={[
                        {
                            required: true,
                            message: 'Please select the county of powerline!',
                        },
                    ]}
                >
                    <Select placeholder="Please select the county" loading={loading.countiesLoading} showSearch filterOption={false} onSearch={(e) => onSearch('countyId', e)}>
                        {counties.map((item, key) => <Option key={key} value={item.id}>{item.county}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="zip"
                    label="Zip"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the zip of powerline!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="construction"
                    label="Construction"
                    rules={[{
                        required: true,
                        message: 'Please input the construction !',
                    }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default UpdatePole;