const { SmileFilled } = require('@ant-design/icons')
export default {
    OPTIONS: {
        side: {
            label: "Sides",
            route: "sides",
            icon: <SmileFilled />,
            type: "side"
        },
        direction: {
            label: "Directions",
            route: "directions",
            icon: <SmileFilled />,
            type: "direction"
        },
        manufacturer: {
            label: "Manufacturers",
            route: "manufacturers",
            icon: <SmileFilled />,
            type: "manufacturer"
        },
        originalTreatment: {
            label: "Original Treatments",
            route: "original_treatments",
            icon: <SmileFilled />,
            type: "originalTreatment"
        },
        species: {
            label: "Species",
            route: "species",
            icon: <SmileFilled />,
            type: "species"
        },
        class: {
            label: "Classes",
            route: "classes",
            icon: <SmileFilled />,
            type: "class"
        },
        construction: {
            label: "Constructions",
            route: "constructions",
            icon: <SmileFilled />,
            type: "construction"
        },
        // inspectionType: {
        //     label: "Inspection Types",
        //     route: "inspection_types",
        //     icon: <SmileFilled />,
        //     type: "inspectionType"
        // },
        // inspectionSubtype: {
        //     label: "Inspection Subtypes",
        //     route: "inspection_subtypes",
        //     icon: <SmileFilled />,
        //     type: "inspectionSubtype"
        // },
        maintenance: {
            label: "Maintenances",
            route: "maintenances",
            icon: <SmileFilled />,
            type: "maintenance"
        },
        location: {
            label: "Locations",
            route: "locations",
            icon: <SmileFilled />,
            type: "location"
        },
        condition: {
            label: "Conditions",
            route: "conditions",
            icon: <SmileFilled />,
            type: "condition"
        },
        advancedCode: {
            label: "Advanced Codes",
            route: "advanced_codes",
            icon: <SmileFilled />,
            type: "advancedCode"
        },
        height: {
            label: "Height",
            route: "heights",
            icon: <SmileFilled />,
            type: "height"
        },
        weather: {
            label: "Weather",
            route: "weathers",
            icon: <SmileFilled />,
            type: "weather"
        },
        preservative: {
            label: "Preservatives",
            route: "preservatives",
            icon: <SmileFilled />,
            type: "preservative"
        }
    }
}