import React, { useEffect } from 'react';
import { Button, Modal, Space, Form, Input, Select, Switch, Checkbox, InputNumber, Tooltip, DatePicker } from 'antd';
import utils from '../../../utils';
import { LinkOutlined } from '@ant-design/icons';

const { Option } = Select;
const UpdateInspection = ({ onCreate, onCancel, loading, initialValues, poles, inspectionCompanies,
    inspectionTypes, maintenances, weathers, locations, conditions, inspectionSubTypes, onSearch }) => {
    const [form] = Form.useForm();
    function handleOk() {
        form
            .validateFields()
            .then((values) => {
                utils.removeEmptyOrNull(values)
                onCreate({ ...values });
                form.resetFields();
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    function handleCancel() {
        onCancel()
        form.resetFields();
    }
    useEffect(() => {
        console.log('Component will mount')
        // returned function will be called on component unmount 
        return () => {
            console.log('Component will unmount')
        }
    }, [])
    return (
        <Modal
            visible={true}
            title="Update Inspection"
            className={'max-width-modal'}
            onCancel={handleCancel}
            onOk={handleOk}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading.updateInspection} onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{ ...initialValues }}
            >
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="poleId"
                        label="Poles"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input the pole !',
                            },
                        ]}
                    >
                        <Select placeholder="Please select the pole"
                            loading={loading.polesLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('poleId', e)}>
                            {poles.map((item, key) =>
                                <Option key={key} value={item.id}>
                                    {item.powerLine.lineName} - {item.poleNumber}
                                </Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px', marginBottom: 0, textAlign: 'center' }}>
                        <Tooltip title="Internal">
                            <Form.Item
                                name="internal"
                                label="Internal"
                                valuePropName="checked"
                                rules={[]}
                                style={{ display: 'inline-block', width: 'calc(18% - 8px)', marginBottom: 0 }}>
                                <Switch defaultChecked />
                            </Form.Item>
                        </Tooltip>
                        <Tooltip title="Crushed Rock Back fill">
                            <Form.Item
                                name="crushedRockBackfill"
                                label="CRBF"
                                valuePropName="checked"
                                style={{ display: 'inline-block', width: 'calc(18% - 8px)', margin: '0 8px' }}
                                rules={[]}
                            >
                                <Switch defaultChecked />
                            </Form.Item>
                        </Tooltip>
                        <Tooltip title="Install Animal Guard">
                            <Form.Item
                                name="installAnimalGuard"
                                label="ISG"
                                valuePropName="checked"
                                rules={[]}
                                style={{ display: 'inline-block', width: 'calc(18% - 8px)', marginBottom: 0 }}>
                                <Switch defaultChecked />

                            </Form.Item>
                        </Tooltip>
                        <Tooltip title="Install Tyres">
                            <Form.Item
                                name="installTires"
                                label="IBT"
                                valuePropName="checked"
                                style={{ display: 'inline-block', width: 'calc(18% - 8px)', margin: '0 8px' }}
                                rules={[]}
                            >
                                <Switch defaultChecked />
                            </Form.Item>
                        </Tooltip>
                        <Tooltip title="Fumigant">
                            <Form.Item
                                name="fumigantVile"
                                label="FV"
                                valuePropName="checked"
                                style={{ display: 'inline-block', width: 'calc(18% - 8px)', margin: '0 8px' }}
                                rules={[]}
                            >
                                <Switch defaultChecked />
                            </Form.Item>
                        </Tooltip>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="inspectionTypeCode"
                        label="Inspection Type"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input the inspection type !',
                            },
                        ]}
                    >
                        <Select placeholder="Please select the inspection type "
                            loading={loading.inspectionTypeLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('inspectionTypeCode', e)}>
                            {inspectionTypes.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="inspectionSubTypeCode"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        label="Inspection Sub Type"
                        rules={[]}
                    >
                        <Select placeholder="Please select the inspection sub type"
                            loading={loading.inspectionSubTypeLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('inspectionSubTypeCode', e)}>
                            {inspectionSubTypes.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        name="year"
                        label="Year"
                        rules={
                            [{
                                required: true,
                                message: 'Please select the year !',
                            }]
                        }
                    >
                        <DatePicker style={{ width: '100%' }} picker="year" />
                    </Form.Item>
                    <Form.Item
                        name="inspectionCompanyId"
                        label="Inspection Company"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input the inspection company !',
                            },
                        ]}
                    >
                        <Select placeholder="Please select the inspection company"
                            loading={loading.inspectionCompaniesLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('inspectionCompanyId', e)}>
                            {inspectionCompanies.map((item, key) =>
                                <Option key={key} value={item.id}>
                                    {item.name}
                                </Option>)}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        name="generalComments"
                        label="General comments"
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="weatherCode"
                        label="Weather"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input the weather !',
                            },
                        ]}
                    >
                        <Select placeholder="Please select the weather code"
                            loading={loading.weathersLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('weatherCode', e)}>
                            {weathers.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="originalCircumference"
                        label="Original Circumference"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input the original circumference !',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            min="0"
                            step="0.01" />
                    </Form.Item>
                    <Form.Item
                        name="effectiveCircumference"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        label="Effective Circumference"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the effective circumference !',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="holeCount"
                        label="Hole count"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input the hole count !',
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} min="0" />
                    </Form.Item>
                    <Form.Item
                        name="installPole"
                        label="Install Pole ?"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[]}
                    >
                        <Checkbox defaultChecked />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="installGGCount"
                        label="Install GG Count"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input the Install GG Count !',
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} min="0" />
                    </Form.Item>
                    <Form.Item
                        name="fixGW"
                        label="Fix GW"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[]}
                    >
                        <InputNumber style={{ width: '100%' }} min="0" />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="maintenanceCode"
                        label="Maintenance Code"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[]}
                    >
                        <Select placeholder="Please select the maintenance code"
                            loading={loading.maintenancesLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('maintenanceCode', e)}>
                            {maintenances.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="maintenanceComments"
                        label="Maintenance Comments"
                        rules={[]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="locationCode"
                        label="Location Code"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[]}
                    >
                        <Select placeholder="Please select the location code"
                            loading={loading.locationsLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('locationCode', e)}>
                            {locations.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="locationComments"
                        label="Location Comments"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="conditionCode"
                        label="Condition Code"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[]}
                    >
                        <Select placeholder="Please select the condition code"
                            loading={loading.conditionsLoading}
                            showSearch filterOption={false}
                            onSearch={(e) => onSearch('conditionCode', e)}>
                            {conditions.map((item, key) =>
                                <Option key={key} value={item.code}>
                                    {item.code}
                                </Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="conditionComments"
                        label="Condition Comments"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="driveRodsCount"
                        label="Drive Rods Count"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[]}
                    >
                        <InputNumber min={1} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="groundWireResistance1"
                        label="Ground Wire Resistance 1"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="groundWireResistance2"
                        label="Ground Wire Resistance 2"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="groundWireResistance3"
                        label="Ground Wire Resistance 3"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        label="Latitude"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <Space>
                            <Form.Item
                                name="lat"
                                noStyle
                                rules={[{
                                    required: true,
                                    message: 'Please input the longitude!',
                                }]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <a href="https://www.google.com/maps" target="_blank" rel="noopener" style={{ paddingLeft: '10px' }}><LinkOutlined /></a>
                        </Space>
                    </Form.Item>
                    <Form.Item
                        label="Longitude"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                    >
                        <Space>
                            <Form.Item
                                name="lng"
                                noStyle
                                rules={[{
                                    required: true,
                                    message: 'Please input the longitude!',
                                }]}
                            >
                                <InputNumber min={1} style={{ width: '100%' }} />
                            </Form.Item>
                            <a href="https://www.google.com/maps" target="_blank" rel="noopener" style={{ paddingLeft: '10px' }}><LinkOutlined /></a>
                        </Space>
                    </Form.Item>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default UpdateInspection;