import React, { Component } from 'react'
import { Table, Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { countyAction } from '../../redux/_actions';
import { Breadcrumb } from 'antd';

class Counties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {},
            selectedRecord: {},
            counties: [],
            totalCounties: 0,
            countiesLoading: false,
            searchText: '',
            searchedColumn: '',
            page: 1,
            modal: {
                addForm: false
            }
        }
    }
    componentDidMount = () => {
        this.getAllCounties()
    }
    toggleModal = (key) => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, [key]: !modal[key] } })
    }
    toggleLoading = (key) => {
        const { loading } = this.state;
        this.setState({ loading: { ...loading, [key]: !loading[key] } })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
        this.getAllCounties({
            searchKey: dataIndex,
            searchValue: selectedKeys[0],
            page: 1
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        this.getAllCounties()
    };
    getAllCounties = async (params) => {
        try {
            this.setState({ countiesLoading: true });
            const resp = await countyAction.getAllCounties(params);
            this.setState({ countiesLoading: false, counties: resp.counties || [], totalCounties: resp.total });
        } catch (error) {
            this.setState({ countiesLoading: false });
        }

    }
    onChangePage = (newPage, pageSize) => {
        const { searchedColumn, searchText, page } = this.state;
        if (page != newPage) { // To prevent unwanted API calls
            const params = { page: newPage, limit: pageSize };
            if (searchedColumn && searchText) {
                params['searchKey'] = searchedColumn
                params['searchValue'] = searchText
            }
            this.getAllCounties(params);
            this.setState({ page: newPage })
        }
    }
    render() {
        const columns = [
            {
                title: 'County',
                dataIndex: 'county',
                key: 'county',
                ...this.getColumnSearchProps('county'),
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                ...this.getColumnSearchProps('state'),
                ellipsis: true,
            }
        ];
        console.log(this.state)
        return (
            <div>
                <Breadcrumb style={{ marginBottom: '10px' }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Counties</Breadcrumb.Item>
                </Breadcrumb>
                <Table rowKey={'county'}
                    pagination={{
                        onChange: this.onChangePage,
                        total: this.state.totalCounties
                    }}
                    columns={columns}
                    loading={this.state.countiesLoading} bordered dataSource={this.state.counties} />
            </div>
        )
    }
}

export default Counties;
