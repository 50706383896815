import React from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';

const { Option } = Select;

const AddNewCustomer = ({ onCreate, onCancel, loading }) => {
    const [form] = Form.useForm();
    function handleOk() {
        form
            .validateFields()
            .then((values) => {
                onCreate({ ...values });
                form.resetFields();
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    function handleCancel() {
        onCancel()
        form.resetFields();
    }
    const prefixSelector = (
        <Select style={{ width: 70 }} defaultValue='1' disabled>
            <Option value="1">+1</Option>
        </Select>
    );
    console.log('COUS')
    return (
        <Modal
            visible={true}
            title="Add new customer"
            onCancel={handleCancel}
            onOk={handleOk}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                }}
            >
                <Form.Item
                    name="customerName"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the name of customer!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the email of customer!',
                        },
                        { type: 'email' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the phone of customer!',
                        },
                    ]}
                >
                    <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name="website"
                    label="Website"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the website of customer!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the address of customer!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="contactName"
                    label="Contact Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the contact name of customer!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="contactEmail"
                    label="Contact Email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the contact email of customer!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="contactPhone"
                    label="Contact Phone"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the contact phone of customer!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default AddNewCustomer;