import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { SketchPicker } from 'react-color';

const UpdateInspectionType = ({ onCreate, onCancel, initialValues, loading }) => {
    const [form] = Form.useForm();
    const [color, setColor] = useState('#22194D');
    function handleOk() {
        form
            .validateFields()
            .then((values) => {
                onCreate({ ...values, color: values.color.hex });
                form.resetFields();
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    function handleCancel() {
        onCancel()
        form.resetFields();
    }
    useEffect(() => {
        setColor(initialValues.color)
        return () => {
            console.log('Component will unmount')
        }
    }, [])
    return (
        <Modal
            visible={true}
            title="Update Inspection Type"
            onCancel={handleCancel}
            onOk={handleOk}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{ ...initialValues }}
            >
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter description!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="color"
                    label="Color"
                    rules={[
                        {
                            required: true,
                            message: 'Please select color!',
                        },
                    ]}
                >
                    <SketchPicker width={'96%'}
                        onChangeComplete={(color) => { setColor(color.hex) }}
                        color={color} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default UpdateInspectionType;