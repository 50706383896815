import { downloadService } from '../_services';
import { message } from 'antd';
import download from "downloadjs";

export const downloadAction = {
    downloadInspections,
    downloadFile
};

function downloadInspections(params) {
    return downloadService.downloadInspections(params)
        .then(
            resp => {
                download(resp, `${params.fileName}.${params.type}`);
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
function downloadFile(path, filename) {
    return downloadService.downloadFile(path)
        .then(
            resp => {
                download(resp, `${filename}`);
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
