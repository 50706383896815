import axios from '../_client/axios';

export const inspectionService = {
    get: async (params) => {
        try {
            const response = await axios.get(`/inspection/${params.id}`);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    getAllInspections: async (params) => {
        try {
            const response = await axios.get(`/inspection`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    delete: async (id) => {
        try {
            const response = await axios.delete(`/inspection/${id}`);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    create: async (params) => {
        try {
            const response = await axios.post(`/inspection`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    update: async (id, params) => {
        try {
            const response = await axios.put(`/inspection/${id}`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    }
};