import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';

const AddNewOption = ({ onCreate, onCancel, loading, type, checkCodeAvailability }) => {
    const [form] = Form.useForm();
    const [codeValidation, setCodeValidation] = useState(null);
    function handleOk() {
        form
            .validateFields()
            .then((values) => {
                onCreate({ ...values });
                form.resetFields();
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    function handleCancel() {
        onCancel()
        form.resetFields();
    }
    async function checkAvailability(e) {
        try {
            console.log(e.target.value)
            const resp = await checkCodeAvailability(type, e.target.value);
            if (resp == null) {
                setCodeValidation("success")

            } else {
                setCodeValidation("error")
            }
        } catch (error) {
            setCodeValidation("warning")
        }
    }
    return (
        <Modal
            visible={true}
            title="Add new option"
            onCancel={handleCancel}
            onOk={handleOk}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                }}
            >
                <Form.Item
                    name="code"
                    label="Code"
                    hasFeedback
                    validateStatus={codeValidation}
                    help="Should be unique"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter code!',
                        },
                    ]}
                >
                    <Input onChange={(e) => checkAvailability(e)} />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter description!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default AddNewOption;