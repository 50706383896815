import { inspectionSubTypeService } from '../_services';
import { message } from 'antd';

export const inspectionSubTypeAction = {
    getAllInspectionSubTypes,
    changeStatus,
    create,
    update,
    deleteItem
};

function getAllInspectionSubTypes(params) {
    return inspectionSubTypeService.getAllInspectionSubTypes(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function changeStatus(id, params) {
    return inspectionSubTypeService.changeStatus(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function create(params) {
    return inspectionSubTypeService.create(params)
        .then(
            resp => {
                message.success('Created');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(id, params) {
    return inspectionSubTypeService.update(id, params)
        .then(
            resp => {
                message.success('Updated');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function deleteItem(id) {
    return inspectionSubTypeService.delete(id)
        .then(
            resp => {
                message.success('Deleted');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
