import { powerlineService } from '../_services';
import { message } from 'antd';

export const powerlineAction = {
    getAllPowerlines,
    changeStatus,
    create,
    update,
    deleteItem
};

function getAllPowerlines(params) {
    return powerlineService.getAllPowerlines(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function changeStatus(id, params) {
    return powerlineService.changeStatus(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function create(params) {
    return powerlineService.create(params)
        .then(
            resp => {
                message.success('Created');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(id, params) {
    return powerlineService.update(id, params)
        .then(
            resp => {
                message.success('Updated');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function deleteItem(id) {
    return powerlineService.delete(id)
        .then(
            resp => {
                message.success('Deleted');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
