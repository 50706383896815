import axios from '../_client/axios';

export const commonService = {
    imageUpload: async (file) => {
        try {
            const response = await axios.post(`/uploads/media`, file, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data.data;
        } catch (e) {
            throw e
        }
    }
};