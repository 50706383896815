import React, { Component } from 'react';
import Loader from '../../components/Loader';
import { Form, Input, Button } from 'antd';
import { userAction } from '../../redux/_actions';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = () => {
  }
  handleSubmit = async (values) => {
    try {
      const { oldPassword, password } = values;
      this.setState({ loading: true });
      await userAction.changePassword({ oldPassword, password });
      this.setState({ loading: false });
      this.props.history.push('/inspections')
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  render() {
    const { loading } = this.state;
    const formItemLayout = {
      labelCol: {
        style: { align: 'left' },
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 0,
          offset: 0,
        },
        sm: {
          span: 0,
          offset: 0,
        },
      },
    };
    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card-group mb-0">
                <div className="card p-2">
                  <div className="card-block text-center">
                    <span className="login-logo"></span>
                    <p className="text-muted">Change your password</p>
                    <Form
                      {...formItemLayout}
                      name="change_password"
                      className="change-password-form"
                      initialValues={{}}
                      onFinish={this.handleSubmit}
                    >
                      <Form.Item
                        name="oldPassword"
                        label="Old Password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your old password!',
                          }
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                          Change
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;