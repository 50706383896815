import React, { Component } from 'react'
import { Table, Tag, Input, Space, Button, Popconfirm } from 'antd';
import { SyncOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { inspectionSubTypeAction } from '../../redux/_actions';
import AddNewInspectionSubType from '../../components/Forms/InspectionSubTypes/Add';
import UpdateInspectionSubType from '../../components/Forms/InspectionSubTypes/Update';
import { Breadcrumb } from 'antd';
import Loader from '../../components/Loader';

class InspectionSubTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {},
            selectedRecord: {},
            totalInspectionSubTypes: 0,
            inspectionSubTypesLoading: false,
            searchText: '',
            searchedColumn: '',
            page: 1,
            modal: {
                addForm: false
            }
        }
    }
    componentDidMount = () => {
        this.getAllInspectionSubTypes()
    }
    toggleModal = (key) => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, [key]: !modal[key] } })
    }
    toggleLoading = (key) => {
        const { loading } = this.state;
        this.setState({ loading: { ...loading, [key]: !loading[key] } })
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
        this.getAllInspectionSubTypes({
            searchKey: dataIndex,
            searchValue: selectedKeys[0],
            page: 1
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
        this.getAllInspectionSubTypes()
    };
    getAllInspectionSubTypes = async (params) => {
        try {
            this.setState({ inspectionSubTypesLoading: true });
            const resp = await inspectionSubTypeAction.getAllInspectionSubTypes(params);
            this.setState({ inspectionSubTypesLoading: false, inspectionSubTypes: resp.inspectionSubtypes || [], totalInspectionSubTypes: resp.total });
        } catch (error) {
            this.setState({ inspectionSubTypesLoading: false });
        }

    }
    confirmStatusChange = async (id, params) => {
        try {
            this.toggleLoading('statusChange');
            await inspectionSubTypeAction.changeStatus(id, params)
            this.getAllInspectionSubTypes();
            this.toggleLoading('statusChange');
        } catch (error) {
            this.toggleLoading('statusChange');
        }
    }
    onChangePage = (newPage) => {
        const { searchedColumn, searchText, page } = this.state;
        if (page != newPage) { // To prevent unwanted API calls
            const params = { page: newPage };
            if (searchedColumn && searchText) {
                params['searchKey'] = searchedColumn
                params['searchValue'] = searchText
            }
            this.getAllInspectionSubTypes(params);
            this.setState({ page: newPage })
        }
    }
    createAndList = async (params) => {
        try {
            this.toggleLoading('addInspectionSubType');
            await inspectionSubTypeAction.create(params)
            this.setState({
                modal: { ...this.state.modal, addForm: false },
                loading: { ...this.state.loading, addInspectionSubType: false }
            });
            this.getAllInspectionSubTypes();
        } catch (error) {
            this.toggleLoading('addInspectionSubType');
        }
    }
    updateAndList = async (params) => {
        try {
            this.toggleLoading('updateInspectionSubType');
            await inspectionSubTypeAction.update(this.state.selectedRecord.code, params)
            this.setState({
                modal: { ...this.state.modal, updateForm: false },
                loading: { ...this.state.loading, updateInspectionSubType: false }
            });
            this.getAllInspectionSubTypes();
        } catch (error) {
            this.toggleLoading('updateInspectionSubType');
        }
    }
    render() {
        const columns = [
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
                ...this.getColumnSearchProps('code'),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('description'),
                ellipsis: true,
            },
            {
                title: 'Color',
                dataIndex: 'color',
                key: 'color',
                align: 'center',
                render: color => <div style={{
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                }}>
                    <div style={{
                        width: '36px',
                        height: '14px',
                        borderRadius: '2px',
                        background: color,
                    }} />
                </div>
            },
            {
                title: 'Status',
                key: 'isActive',
                width: '15%',
                render: record =>
                    <Space size="middle">
                        <Popconfirm
                            title={`Are you sure want to ${record.isActive ? 'deactivate' : 'activate'} this inspection sub type?`}
                            onConfirm={() => this.confirmStatusChange(record.code, {
                                status: record.isActive ? 'deactivate' : 'activate'
                            })}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tag color={record.isActive ? 'green' : 'red'}>{record.isActive ? 'ACTIVE' : 'DEACTIVATED'}</Tag> <SyncOutlined style={{ cursor: 'pointer' }} />
                        </Popconfirm>
                    </Space>
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <EditOutlined
                            onClick={() => {
                                this.toggleModal('updateForm')
                                this.setState({ selectedRecord: record })
                            }} />
                    </Space>
                ),
            }
        ];
        return (
            <div>
                <Loader loading={this.state.loading.statusChange} text={"Updating status"} />
                {this.state.modal.addForm && <AddNewInspectionSubType
                    loading={this.state.loading.addInspectionSubType}
                    onCreate={this.createAndList}
                    onCancel={() => this.toggleModal('addForm')}
                />}
                {this.state.modal.updateForm && <UpdateInspectionSubType
                    loading={this.state.loading.updateInspectionSubType}
                    initialValues={this.state.selectedRecord}
                    onCreate={(e) => {
                        this.updateAndList(e);
                        this.setState({ selectedRecord: {} })
                    }}
                    onCancel={() => {
                        this.toggleModal('updateForm');
                        this.setState({ selectedRecord: {} })
                    }}
                />}
                <Breadcrumb>
                    <Breadcrumb.Item key="1">Home</Breadcrumb.Item>
                    <Breadcrumb.Item key="2">Inspection Sub Types</Breadcrumb.Item>
                </Breadcrumb>
                <div className="add-new-btn">
                    <Button
                        type="primary"
                        onClick={() => {
                            this.toggleModal('addForm')
                        }}>
                        New Inspection Sub Type
                </Button>
                </div>
                <Table rowKey={'code'}
                    pagination={{
                        onChange: this.onChangePage,
                        total: this.state.totalInspectionSubTypes
                    }}
                    columns={columns}
                    loading={this.state.inspectionSubTypesLoading} bordered dataSource={this.state.inspectionSubTypes} />
            </div>
        )
    }
}

export default InspectionSubTypes;
