import axios from '../_client/axios';

export const optionService = {
    getAllOptions: async (type, params) => {
        try {
            const response = await axios.get(`/option/${type}`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    changeStatus: async (type, id, params) => {
        try {
            const response = await axios.put(`/option/${type}/${id}/status`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    create: async (type, params) => {
        try {
            const response = await axios.post(`/option/${type}`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    update: async (type, id, params) => {
        try {
            const response = await axios.put(`/option/${type}/${id}`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    checkCodeAvailability: async (type, id) => {
        try {
            const response = await axios.get(`/option/${type}/${id}/available`);
            return response.data.data;
        } catch (e) {
            throw e
        }
    }
};