import React, { Component } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { Layout } from 'antd';

const { Content } = Layout;
class Full extends Component {
    render() {
        return (
            <div className="app">
                <Layout className="layout">
                    <Header />
                    <div className="app-body">
                        <Sidebar {...this.props} />
                        <main className="main">
                            <div className="container-fluid">
                                <Content>{this.props.children}</Content>
                            </div>
                        </main>
                    </div>
                    <Footer />
                </Layout>
            </div>
        );
    }
}

export default Full;
