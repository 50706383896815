import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  ImportOutlined, SolutionOutlined, UserOutlined, SlidersOutlined, LinkedinOutlined, FormOutlined, WomanOutlined
  , SubnodeOutlined, EnvironmentOutlined, InsertRowAboveOutlined, InsertRowBelowOutlined
} from '@ant-design/icons';
import constants from "../../constants";
import { authActions } from '../../redux/_actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../Loader';

class Sidebar extends Component {

  componentDidMount = async () => {
    if (!this.props.user)
      await this.props.validateToken();
  }
  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  activeRoute(routeName) {
    let location = this.props.location.location || this.props.location;
    return location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
  }
  onClickLogout = () => {
    localStorage.clear();
  }
  render() {
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <Loader loading={!this.props.user} text={"Checking authentication..."} />
          <ul className="nav">
            <li className="nav-item" className={this.activeRoute("/inspections")}>
              <NavLink to={'/inspections'} className="nav-link" activeClassName="active"><FormOutlined /> Inspections</NavLink>
            </li>
            <li className="nav-item" className={this.activeRoute("/poles")}>
              <NavLink to={'/poles'} className="nav-link" activeClassName="active"><WomanOutlined /> Poles</NavLink>
            </li>
            <li className="nav-item" className={this.activeRoute("/powerlines")}>
              <NavLink to={'/powerlines'} className="nav-link" activeClassName="active"><SubnodeOutlined /> Powerlines</NavLink>
            </li>
            <li className="nav-item" className={this.activeRoute("/customers")}>
              <NavLink to={'/customers'} className="nav-link" activeClassName="active"><SolutionOutlined /> Customers</NavLink>
            </li>
            <li className="nav-item" className={this.activeRoute("/inspection_type")}>
              <NavLink to={'/inspection_type'} className="nav-link" activeClassName="active"><InsertRowAboveOutlined /> Inspection Types</NavLink>
            </li>
            <li className="nav-item" className={this.activeRoute("/inspection_sub_type")}>
              <NavLink to={'/inspection_sub_type'} className="nav-link" activeClassName="active"><InsertRowBelowOutlined /> Inspection Sub Types</NavLink>
            </li>
            <li className="nav-item" className={this.activeRoute("/inspection_companies")}>
              <NavLink to={'/inspection_companies'} className="nav-link" activeClassName="active"><SolutionOutlined /> Inspection Companies</NavLink>
            </li>
            <li className="nav-item" className={this.activeRoute("/counties")}>
              <NavLink to={'/counties'} className="nav-link" activeClassName="active"><EnvironmentOutlined /> Counties</NavLink>
            </li>
            <li className="nav-item" className={this.activeRoute("/jobs")}>
              <NavLink to={'/jobs'} className="nav-link" activeClassName="active"><LinkedinOutlined /> Jobs</NavLink>
            </li>
            <li className="nav-item" className={this.activeRoute("/users")}>
              <NavLink to={'/users'} className="nav-link" activeClassName="active"><UserOutlined /> Users</NavLink>
            </li>
            <li className={this.activeRoute("/options")}>
              <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><SlidersOutlined /> Options</a>
              <ul className="nav-dropdown-items">
                {Object.keys(constants.OPTIONS).map(item =>
                  <li className="nav-item" key={item} className={this.activeRoute(`/options/${constants.OPTIONS[item].route}`)}>
                    <NavLink to={`/options/${constants.OPTIONS[item].route}`} className="nav-link" activeClassName="active"><SlidersOutlined /> {constants.OPTIONS[item].label}</NavLink>
                  </li>
                )}
              </ul>
            </li>
            <li className="nav-item" className={this.activeRoute("/logout")} onClick={this.onClickLogout}>
              <NavLink to={'/login'} className="nav-link" activeClassName="active"><ImportOutlined /> Logout</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}

function mapState(state) {
  const { user } = state.authState;
  return { user };
}

const actionCreators = {
  validateToken: authActions.validateToken,
};


export default withRouter(connect(mapState, actionCreators)(Sidebar));
