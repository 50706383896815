import { jobApplicantService } from '../_services';

export const jobApplicantAction = {
    getAllJobApplicants
};

function getAllJobApplicants(id, params) {
    return jobApplicantService.getAllJobApplicants(id, params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}
