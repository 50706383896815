import React, { Component } from 'react'
import { Space, Col, Row, Card, Popover, Table, Image, Popconfirm, PageHeader, Button, Descriptions } from 'antd';
import moment from "moment";
import { inspectionAction, poleAction, optionAction, inspectionTypeAction, inspectionSubTypeAction } from '../../redux/_actions';
import UpdateInspection from '../../components/Forms/Inspections/Update';
import { EnvironmentFilled } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import Loader from '../../components/Loader';
import MapView from '../../components/MapView';

const { Meta } = Card;

export default class InspectionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            loading: {},
            inspection: {},
            poles: [],
            inspectionTypes: [],
            inspectionSubTypes: [],
            locations: [],
            conditions: [],
            maintenances: [],
            modal: {
                addForm: false
            }
        }
    }
    componentDidMount = () => {
        this.getInspection();
    }
    getInspection = async () => {
        try {
            this.toggleLoading('inspectionLoading');
            const id = this.props.match.params.id;
            const resp = await inspectionAction.get({ id });
            this.setState({
                loading: { ...this.state.loading, inspectionLoading: false },
                id, inspection: resp || {}
            });
        } catch (error) {
            console.log(error)
            this.toggleLoading('inspectionLoading');
        }
    }
    toggleLoading = (key) => {
        const { loading } = this.state;
        this.setState({ loading: { ...loading, [key]: !loading[key] } })
    }
    toggleModal = (key) => {
        const { modal } = this.state;
        this.setState({ modal: { ...modal, [key]: !modal[key] } })
    }
    updateAndGet = async (params) => {
        try {
            this.toggleLoading('updateInspection');
            await inspectionAction.update(this.state.inspection.id, { ...params })
            this.setState({
                modal: { ...this.state.modal, updateForm: false },
                loading: { ...this.state.loading, updateInspection: false }
            });
            this.getInspection();
        } catch (error) {
            this.toggleLoading('updateInspection');
        }
    }
    deleteRecord = async (params) => {
        try {
            this.toggleLoading('deleteInspection');
            await inspectionAction.deleteItem(params)
            this.setState({
                loading: { ...this.state.loading, deleteInspection: false }
            });
            this.props.history.push(`/inspections`)
        } catch (error) {
            this.toggleLoading('deleteInspection');
        }
    }
    getAllPoles = async (params) => {
        try {
            this.toggleLoading('polesLoading');
            const resp = await poleAction.getAllPoles(params);
            this.setState({ loading: { ...this.state.loading, polesLoading: false }, poles: resp.poles || [] });
        } catch (error) {
            this.toggleLoading('polesLoading');
        }
    }
    getAllInspectionTypes = async (params) => {
        try {
            this.toggleLoading('inspectionTypesLoading');
            const resp = await inspectionTypeAction.getAllInspectionTypes(params);
            this.setState({ loading: { ...this.state.loading, inspectionTypesLoading: false }, inspectionTypes: resp.inspectionTypes || [] });
        } catch (error) {
            this.toggleLoading('inspectionTypesLoading');
        }
    }
    getAllInspectionSubTypes = async (params) => {
        try {
            this.toggleLoading('inspectionSubTypesLoading');
            const resp = await inspectionSubTypeAction.getAllInspectionSubTypes(params);
            this.setState({ loading: { ...this.state.loading, inspectionSubTypesLoading: false }, inspectionSubTypes: resp.inspectionSubtypes || [] });
        } catch (error) {
            this.toggleLoading('inspectionSubTypesLoading');
        }
    }
    getAllLocations = async (params) => {
        try {
            this.toggleLoading('locationsLoading');
            const resp = await optionAction.getAllOptions('location', params);
            this.setState({ loading: { ...this.state.loading, locationsLoading: false }, locations: resp.options || [] });
        } catch (error) {
            this.toggleLoading('locationsLoading');
        }
    }
    getAllConditions = async (params) => {
        try {
            this.toggleLoading('conditionsLoading');
            const resp = await optionAction.getAllOptions('condition', params);
            this.setState({ loading: { ...this.state.loading, conditionsLoading: false }, conditions: resp.options || [] });
        } catch (error) {
            this.toggleLoading('conditionsLoading');
        }
    }
    getAllMaintenances = async (params) => {
        try {
            this.toggleLoading('maintenancesLoading');
            const resp = await optionAction.getAllOptions('maintenance', params);
            this.setState({ loading: { ...this.state.loading, maintenancesLoading: false }, maintenances: resp.options || [] });
        } catch (error) {
            this.toggleLoading('maintenancesLoading');
        }
    }
    render() {
        const { inspection } = this.state;
        const photoColumns = [{
            title: 'Photos',
            key: 'photo',
            dataIndex: 'photoUrl',
            align: 'center',
            render: record => <Image width={100} src={record} />,
        }, {
            title: 'Comments',
            key: 'comments',
            dataIndex: 'comments',
            ellipsis: true,
        }]
        return (
            <div className='details-container'>
                <Loader loading={this.state.loading.inspectionLoading || this.state.loading.updateInspection || this.state.loading.deleteInspection} text={"Updating status"} />
                {this.state.modal.updateForm &&
                    <UpdateInspection
                        loading={this.state.loading}
                        initialValues={this.state.inspection}
                        poles={this.state.poles}
                        inspectionTypes={this.state.inspectionTypes}
                        inspectionSubTypes={this.state.inspectionSubTypes}
                        locations={this.state.locations}
                        conditions={this.state.conditions}
                        maintenances={this.state.maintenances}
                        onSearch={this.onSearchFormFields}
                        onCreate={(e) => {
                            this.updateAndList(e);
                            this.setState({ inspection: {} })
                        }}
                        onCancel={() => {
                            this.toggleModal('updateForm');
                            this.setState({ inspection: {} })
                        }}
                    />
                }
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Inspections</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.props.match.params.id}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-page-header-ghost-wrapper">
                    <PageHeader
                        ghost={false}
                        title={`Created by ${inspection?.createdUser?.name}`}
                        subTitle={`Updated by ${inspection?.updatedUser?.name}`}
                        extra={[
                            <Button key="2" onClick={() => {
                                this.getAllPoles()
                                this.getAllInspectionSubTypes()
                                this.getAllInspectionTypes()
                                this.getAllLocations()
                                this.getAllConditions()
                                this.getAllMaintenances()
                                this.toggleModal('updateForm')
                            }}>Edit</Button>,
                            <Popconfirm
                                key="3"
                                title="Are you sure delete this inspection?"
                                onConfirm={() => this.deleteRecord(this.props.match.params.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button>Delete</Button>,
                            </Popconfirm>
                        ]}
                    >
                        <Descriptions size="small" column={2}>
                            <Descriptions.Item label="Created">{inspection?.createdUser?.name}</Descriptions.Item>
                            <Descriptions.Item label="Updated">{inspection?.updatedUser?.name}</Descriptions.Item>
                            <Descriptions.Item label="Created At">{moment(inspection?.createdOn).format('DD-MM-YYYY')}</Descriptions.Item>
                            <Descriptions.Item label="Updated At">{inspection?.updatedOn ? moment(inspection?.updatedOn).format('DD-MM-YYYY') : 'No updation'}</Descriptions.Item>
                        </Descriptions>
                    </PageHeader>
                </div>
                <Card
                    style={{ width: '100%', height: '100%', marginTop: 16 }}>
                    <div className="site-card-wrapper">
                        <Row gutter={12}>
                            <Col span={6}>
                                <Card title="Pole" bordered={true}>
                                    {inspection?.poleId || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Inspection Type" bordered={true}>
                                    {inspection?.inspectionTypeCode || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Inspection Sub Type" bordered={true}>
                                    {inspection?.inspectionSubTypeCoder || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Weather" bordered={true}>
                                    {inspection?.weather || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Card title="Internal" bordered={true}>
                                    {inspection?.internal ? 'Yes' : 'No'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Original Circumference" bordered={true}>
                                    {inspection?.originalCircumference || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Effective Circumference" bordered={true}>
                                    {inspection?.effectiveCircumference || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Hole Count" bordered={true}>
                                    {inspection?.holeCount || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Card title="Fix GW" bordered={true}>
                                    {inspection?.fixGW || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="General Comments" bordered={true}>
                                    {inspection?.generalComments || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Maintenance" bordered={true}>
                                    {inspection?.maintenanceCode || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Maintenance Comments" bordered={true}>
                                    {inspection?.maintenanceComments || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Card title="Location" bordered={true}>
                                    {inspection?.locationCode || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Location Comments" bordered={true}>
                                    {inspection?.locationComments || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Condition" bordered={true}>
                                    {inspection?.conditionCode || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Condition Comments" bordered={true}>
                                    {inspection?.conditionComments || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Card title="Drive Rods Count" bordered={true}>
                                    {inspection?.driveRodsCount || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Ground Wire Resistance1" bordered={true}>
                                    {inspection?.groundWireResistance1 || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Ground Wire Resistance2" bordered={true}>
                                    {inspection?.groundWireResistance2 || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Ground Wire Resistance3" bordered={true}>
                                    {inspection?.groundWireResistance3 || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Card title="Advance Inspection" bordered={true}>
                                    {inspection?.advancedInspection?.advancedCode || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Crushed Rock Back fill" bordered={true}>
                                    {inspection?.advancedInspection?.crushedRockBackfill ? 'Yes' : 'No'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Install Animal Guard" bordered={true}>
                                    {inspection?.advancedInspection?.installAnimalGuard ? 'Yes' : 'No'}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Tires?" bordered={true}>
                                    {inspection?.advancedInspection?.installTires ? 'Yes' : 'No'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="Fumigant" bordered={true}>
                                    {inspection?.fumigantVile ? "Yes" : 'No'}
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Install Pole" bordered={true}>
                                    {inspection?.installPole || 'Not updated'}
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Install GG Count" bordered={true}>
                                    {inspection?.installGGCount || 'Not updated'}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Card title="Location" bordered={true}>
                                    <Popover content={<MapView location={{ lat: inspection.lat, lng: inspection.lng }} />} title="Location" trigger="click">
                                        <EnvironmentFilled />
                                    </Popover>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card title="Horizontal Decay" bordered={true}>
                                    <Image width={100} src={inspection.decayDiagram?.horizontalDecayUrl} />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card title="Vertical Decay" bordered={true}>
                                    <Image width={100} src={inspection.decayDiagram?.verticalDecayUrl} />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="site-card-wrapper">
                        <Table rowKey={'id'}
                            columns={photoColumns}
                            loading={this.state.loading.inspectionLoading} bordered dataSource={inspection.photos || []} />
                    </div>
                </Card>
            </div>
        )
    }
}
