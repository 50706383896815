import React from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';

const { Option } = Select;

const AddNewPowerline = ({ onCreate, onCancel, loading, customers, onSearch }) => {
    const [form] = Form.useForm();
    function handleOk() {
        form
            .validateFields()
            .then((values) => {
                onCreate({ ...values });
                form.resetFields();
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    function handleCancel() {
        onCancel()
        form.resetFields();
    }
    return (
        <Modal
            visible={true}
            title="Add new powerline"
            onCancel={handleCancel}
            onOk={handleOk}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading.addPowerline} onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                }}
            >
                <Form.Item
                    name="lineName"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the name of powerline!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="line"
                    label="Line"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the line of powerline!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="subStation"
                    label="Sub Station"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the sub station of powerline!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="customerId"
                    label="Customer"
                    rules={[
                        {
                            required: true,
                            message: 'Please select the customer of powerline!',
                        },
                    ]}
                >
                    <Select placeholder="Please select the customer" loading={loading.customersLoading} showSearch filterOption={false} onSearch={(e) => onSearch('customerId', e)}>
                        {customers.map((item, key) => <Option key={key} value={item.id}>{item.customerName}</Option>)}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default AddNewPowerline;