import axios from '../_client/axios';

export const jobApplicantService = {
    getAllJobApplicants: async (id, params) => {
        try {
            const response = await axios.get(`/job/${id}/applicants`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    }
};