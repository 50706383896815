import { authConstants } from '../../_constants';
import { history } from '../../_helpers';

const INITIAL_STATE = { user: null };
export function authState(state = INITIAL_STATE, action) {
  switch (action.type) {
    case authConstants.USER_LOGIN:
      return { user: action.user };
    case authConstants.USER_LOGOUT:
      localStorage.clear();
      return INITIAL_STATE;
    case authConstants.USER_UNAUTHORIZED:
      localStorage.clear();
      history.push('/login')
      history.go(0)
      return INITIAL_STATE;
    default:
      return state
  }
}