import { countyService } from '../_services';

export const countyAction = {
    getAllCounties,
};

function getAllCounties(params) {
    return countyService.getAllCounties(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}