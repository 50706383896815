import axios from '../_client/axios';

export const poleService = {
    get: async (params) => {
        try {
            const response = await axios.get(`/pole/${params.id}`);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    getAllPoles: async (params) => {
        try {
            const response = await axios.get(`/pole`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    },

    delete: async (id) => {
        try {
            const response = await axios.delete(`/pole/${id}`);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    create: async (params) => {
        try {
            const response = await axios.post(`/pole`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    update: async (id, params) => {
        try {
            const response = await axios.put(`/pole/${id}`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    getInspectionByPoleId: async (id, params) => {
        try {
            const response = await axios.get(`/pole/${id}/inspections`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
};