import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">
        <a href="">Pole Traq</a> &copy; 2021 Pole Traq.
        <span className="float-right">Powered by <a href="http://www.sbs-inspections.com/">SBS Inspections</a></span>
      </footer>
    )
  }
}

export default Footer;
