import axios from '../_client/axios';

export const inspectionCompanyService = {
    getAllInspectionCompanies: async (params) => {
        try {
            const response = await axios.get(`/inspection_company`, { params });
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    changeStatus: async (id, params) => {
        try {
            const response = await axios.put(`/inspection_company/${id}/status`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    create: async (params) => {
        try {
            const response = await axios.post(`/inspection_company`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    },
    update: async (id, params) => {
        try {
            const response = await axios.put(`/inspection_company/${id}`, params);
            return response.data.data;
        } catch (e) {
            throw e
        }
    }
};