import React from 'react';
import { Button, Modal, Space, Form, Select, DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Filter = ({ onSubmit, onCancel, loading, customers, onSearch }) => {
    const [form] = Form.useForm();
    function handleOk() {
        form
            .validateFields()
            .then((values) => {
                const { customerId } = values;
                const filter = {
                    customerId: customerId || null,
                    from: values.range?.[0]?.format('YYYY-MM-DD') || null,
                    to: values.range?.[1]?.format('YYYY-MM-DD') || null,
                };
                Object.keys(filter).forEach(k => (!filter[k] && filter[k] !== undefined) && delete filter[k])
                onSubmit(filter);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
    function handleCancel() {
        onCancel()
        form.resetFields();
    }
    return (
        <Modal
            visible={true}
            title="Filter Inspections"
            onCancel={handleCancel}
            onOk={handleOk}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading.downloadForm} onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    type: 'csv'
                }}
            >
                <Form.Item
                    name="customerId"
                    label="Customer"
                    rules={[]}
                >
                    <Select allowClear placeholder="Please select the customer" loading={loading.customersLoading} showSearch filterOption={false} onSearch={(e) => onSearch('customerId', e)}>
                        {customers.map((item, key) => <Option key={key} value={item.id}>{item.customerName}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item name="range" label="Range"
                    rules={[]}>
                    <RangePicker style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default Filter;