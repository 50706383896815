import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import { Main } from './main';
import { store } from './redux/_helpers';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import './index.css';
import './styles/css/style.css';
import './styles/css/custom.css';

require('dotenv').config()
ReactDOM.render(
  <Provider store={store}>
    <Main />
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
