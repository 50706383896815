import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import { Form, Input, Button, Checkbox, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { authActions } from '../../redux/_actions';
import { withRouter } from 'react-router-dom';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = () => {
  }
  handleSubmit = async (values) => {
    try {
      const { username, password } = values;
      this.setState({ loading: true });
      await this.props.login({ username, password });
      this.setState({ loading: false });
      this.props.history.push('/inspections')
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  render() {
    const { loading } = this.state;
    return (
      <div className="app flex-row align-items-center">
        <div className="container">
          {loading &&
            <Loader />
            ||
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card-group mb-0">
                  <div className="card p-2">
                    <div className="card-block text-center">
                      <span className="login-logo"></span>
                      <p className="text-muted">Sign In to your account</p>
                      <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={this.handleSubmit}
                      >
                        <Form.Item
                          name="username"
                          rules={[{ required: true, message: 'Please provide your username!' }]}
                        >
                          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          rules={[{ required: true, message: 'Please provide your password!' }]}
                        >
                          <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                          />
                        </Form.Item>
                        {/* <Form.Item>
                          <Link to="/forgot-password" className="btn-link">Forgot password?</Link>
                        </Form.Item> */}
                        <Form.Item>
                          <Button type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                        </Form.Item>
                        {/* <Form.Item>
                          <Link to="/signup" className="btn btn-link">Register</Link>
                        </Form.Item> */}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}


function mapState(state) {
  return {};
}

const actionCreators = {
  login: authActions.login,
  logout: authActions.logout
};

export default withRouter(connect(mapState, actionCreators)(Login));