import { inspectionCompanyService } from '../_services';
import { message } from 'antd';

export const inspectionCompanyAction = {
    getAllInspectionCompanies,
    changeStatus,
    create,
    update
};

function getAllInspectionCompanies(params) {
    return inspectionCompanyService.getAllInspectionCompanies(params)
        .then(
            resp => resp,
            error => {
                throw error;
            }
        );
}

function changeStatus(id, params) {
    return inspectionCompanyService.changeStatus(id, params)
        .then(
            resp => {
                message.success('Done');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function create(params) {
    return inspectionCompanyService.create(params)
        .then(
            resp => {
                message.success('Created');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}

function update(id, params) {
    return inspectionCompanyService.update(id, params)
        .then(
            resp => {
                message.success('Updated');
                return resp;
            },
            error => {
                message.error('Failed');
                throw error;
            }
        );
}
